<template lang="">
     <div>
        <div class="  descriptions_wrapper my-5">
            <div class="tabs">
             
              <template v-if="loader">
                <div v-for="item in 1" :key="item" class="row mt-3 mb-2">
                  <div v-for="it in 1" class="col-3" :key="it">
                    <PuSkeleton width="200px"  height="60px">
                    </PuSkeleton>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-3">
                        <PuSkeleton :count="6" height="10px">
                        </PuSkeleton>
                      </div>
                      <div class="col-9">
                        <div class="row" v-for="main in 3" :key="main">
                          <div class="col-4" v-for="sub in 3" :key="sub">
                            <div class="row">
                              <div class="col-5">
                                <PuSkeleton  height="60px" >
                                </PuSkeleton>
                              </div>
                              <div class="col-7">
                                <PuSkeleton height="30px" >
                                </PuSkeleton>
                                <PuSkeleton height="10px">
                                </PuSkeleton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                 
                  </div>
                 </div> 
              </template>
              <template v-if="loader==false">
                <ul class="tabs__header">
              
                  <li class="tabs__header-item" v-for="(regulation,HeadIndex) in brokersByRegulations" :key="HeadIndex" v-on:click="selectTab(HeadIndex+1)" v-bind:class="{active: activeTab == HeadIndex+1}">
                      <h6 class='m-0 p-0'>{{regulation.name}}</h6>
                  </li>
                </ul>
                <div class="tabs__container">
                  <ul class="tabs__list" ref="tabsList">
                    <li class="tabs__list-tab mt-3" v-for="(regulation,ConIndex) in brokersByRegulations" :key="ConIndex" v-bind:class="{active: activeTab == ConIndex+1}"> 
                      <div class="row">
                        <div class="col-lg-12 tab_content_wrapper">
                       <div class="row">
                          <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                              {{regulation.content}}
                          </div>
                          <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                              <div class="row">
                                  <div v-for="(broker,itemKey) in regulation.brokers" :key="itemKey" class="col-lg-4 col-md-12 col-sm-12 col-12">
                                      <div class="groupItems">
                                          <div class="leftImg" style="width:60px;height:60px">
                                              <img :src="broker.profile" :alt="broker.name" class="w-100 h-100">
                                          </div>
                                          <div class="rightContent">
                                              <a :href="broker.live_url" target="_blank" class="btn btn-sm btn-primary mb-2" >APPLY NOW</a>
                                              <h6 class="groupItemName m-0"> {{broker.name}} </h6>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                       </div>
                        </div> 
                      </div>
                    </li>
                  </ul>
                </div>
              </template>
            
            </div>
            
           </div>
     </div>
</template>
<script>
export default {

    props: {
    datas: {
      type: Array,
      default: () => [],
    },
    darkClass: {
      type: Object,
      default: () => {},
    },
    signup: {
      type: Boolean,
      default: true,
    },
    trendingBigPost: {
      type: Boolean,
      default: true,
    },
    trendingShortPost: {
      type: Boolean,
      default: true,
    },
    ad: {
      type: Boolean,
      default: true,
    },
    sharePost: {
      type: Boolean,
      default: true,
    },
  },
    data() {
        return {
          loader:false,
          brokersByRegulations:[],
   pagination: {
                grand_total: 0,
        current: 1,
        total: 1,
        perPage: 5,
      },
    activeTab: 1,
    tabs: [
  {
    id: 1,
    name: 'CySEC',
    content: 'CySEC or Cyprus Securities and Exchange Commission started its operation from 2001. But it ‘s operation, and financial regulations became a component of European MFiD financial harmonization law after Cyprus joined the European Union in 2004. Today it is one of the trusted supervisors of the European market.',
    items:[
        {
        id: 1,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 2,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 3,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 4,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 5,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 6,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 7,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 8,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 9,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
         

    ],    
},
  {
    id: 2,
    name: 'FCA',
    content: 'FCA or Financial Conduct Authority was founded in April 2013. Despite being a regulatory farm of US, it operates independently of the UK government. Right now it regulates over 50 thousands financial services firms and financial markets in the UK. And FCA sign is very popular and trustworthy among the brokers around the world.',
    items:[
        {
        id: 1,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 2,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 3,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 4,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 5,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 6,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 7,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 8,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 9,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        }, 

    ],  
  },
  {
    id: 3,
    name: 'ASIC',
    content: 'ASIC or The Australian Securities and Investments Commission enforces and regulates Australian financial corporations and everything related to it like investors, creditors etc. Although this is an Australian government’s organization, it works as a complete autonomous organization to maintain its transparency inside the financial market.',
    items:[
        {
        id: 1,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 2,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 3,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 4,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 5,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 6,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 7,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 8,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 9,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        }, 

    ],  
  },
  {
    id: 4,
    name: 'CFTC',
    content: 'CFTC or Commodity Futures Trading Commission started its journey on 1974 as an independent organization of the US government. The primary role of this agency is to regulate the futures and options markets and cease all the fraudulent activities in trading of future conducts.',
    items:[
        {
        id: 1,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 2,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 3,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 4,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 5,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 6,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 7,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 8,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        },
        {
        id: 9,
        logo: 'https://placehold.co/85x52',
        name: 'FXCM Review',
        url:'https://www.google.com'
        }, 

    ],  
  },
  ],
        };
    },
    created() {
      this.getBrokerByRegulations();
    },
    methods: {
      getBrokerByRegulations(){
      this.loader = true;
      this.brokersByRegulations = [];
   
            this.$axios
                .get("/global/website/regulators/broker", {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                  this.brokersByRegulations = result.data; 
          this.loader = false;
                })
                .catch((err) => {
                    this.loader = false;
                    if (err.response) {
                     console.log(err)
                    }

                });
    },
        selectTab(id) {
      let tabsList = this.$refs.tabsList;
      this.activeTab = id;
         tabsList.childNodes.forEach((tab, index) => {
           if (tab.classList.contains('active')) {
             tab.classList.remove('active');
             tab.classList.add('d-none');
           }
           if (index === id - 1) {
             tab.classList.add('active');
             tab.classList.remove('d-none');
           }
         });
    },
  },
}
</script>
<style scoped>
.groupItems {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background: white;
    border-left: 3px solid #1091ff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 0 8px 0px rgba(0,0,0,.2);
    padding: 10px;
    align-items: center;
}
.tabs {
    width: 100%;
    /* max-width: 400px; */
    margin: 0 auto;
  }
  .tabs__header {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .tabs__header-item {
    flex: 1;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    transition: background 0.3s ease;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
  }
  .tabs__header-item:first-child {
    border-radius: 4px 0 0 0;
  }
  .tabs__header-item:last-child {
    border-radius: 0 4px 0 0;
    border-right: 0;
  }
  .tabs__header-item:hover {
    background: rgba(102, 189, 108, 0.1);
  } 
  .tabs__header-item:after {
    content: "";
    width: 0%;
    height: 1px;
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0%);
    /* background: #66bd6c; */
    transition: width 0.3s ease;
  }
  .tabs__header-item.active:after {
    width: 100%;
  }
  .tabs__header-item.active:before {
    background: #66bd6c;
  }
  .tabs__container {
    background: #fff;
    position: relative;
    /* overflow-y: hidden; */
  }
  .tabs__list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    transition: all 0.3s linear;
  }
  .tabs__list-tab.active {
    opacity: 1;
    display: block !important;
 
  }
 .tabs__list-tab {
   width: 100%;
   min-width: 100%;
   padding: 10px 10px;
   box-sizing: border-box;
   position: relative;
   transition: opacity 0.5s ease;
   opacity: 0;
   display: none !important;
 }

  .tab_content_wrapper p{
    color: var(--Gray-Scale-Gray-500, #808080);

/* Body Small/Body Small, 400 */
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
  }
  .tab_content_list{
    list-style: none;
  }
  .tab_content_list li{
    color: var(--Gray-Scale-Gray-500, #808080);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
margin-bottom: 10px;
  }
</style>