<template>
  <div
    :class="[
      'newspark-header-main-menu',
      darkClass && 'menuItem' in darkClass ? darkClass.menuItem : '',
    ]"
  >
    <ul v-if="nav_items">
      <li v-for="(item, index) in nav_items" :key="index">
        <a v-if="item.child"
          >{{ item.linkText }}
          <i v-if="item.child" class="fal fa-angle-down"></i
        ></a>
        <router-link v-else :to="`${item.link}`">{{
          item.linkText
        }}</router-link>
        <ul v-if="item.child" class="sub-menu">
          <li v-for="(lvlTwo, index) in item.submenu" :key="index">
            <router-link :to="`${lvlTwo.link}`">
              {{ lvlTwo.linkText }}
              <i v-if="lvlTwo.child" class="fal fa-angle-down"></i
            ></router-link>
            <ul v-if="lvlTwo.child" class="sub-menu">
              <li v-for="(lvlThree, index) in lvlTwo.third_menu" :key="index">
                <router-link :to="`${lvlThree.link}`">{{
                  lvlThree.linkText
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <div class="search-form position-relative ">
          <input type="text" class="search_field" @keyup.enter="searchAll()" placeholder="Search" v-model="search" name="q">
          <span @click="searchAll()"   class="search-icon position-absolute"><i class="fa fa-search"></i></span>
      </div>
      </li>
    </ul>
    
  </div>
</template>

<script>
import navItems from "../../Data/NavItems.json";
export default {
  props: {
    darkClass: {
      type: Object,
    },
  },
  data() {
    return {
      nav_items: navItems.data, // when you have api then delete json file (dir:components/data/) and add your data this variable
      search: '',      
    }
  },

  methods: {
    searchAll(){
      // trim search value
      console.log('click')
    if(this.search.trim() != ''){
      this.$router.push({ path: '/search', query: { q: this.search.trim()} });      
    }else{
  this.$toast.open({
                message: "Please enter search keyword",
                type: 'error',
            });
    }
      
    }
  },

};


</script>

<style>

span.search-icon{
  right: 8px;
  top: 5px;
  cursor: pointer;
}

input.search_field {
  border-radius: 16px;
  padding: 2px 10px;
  border: 1px solid #6c757d;
}

.newspark-header-main-menu.header-two ul > li > a {
  color: #ffffff;
}
.newspark-header-main-menu.header-three-nav ul > li > a {
  color: white;
}
.header-three-nav {
  margin-left: 35px;
}
</style>
