<template>
  <category-layout>
    <section class="about-item-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about-author-content pt-15">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li class="breadcrumb-item"><a href="#">Category</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Features
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="about-tab-btn mt-40">
              <div class="archive-btn">
                <ul>
                  <li>
                    <span>Category: <span>Features</span></span>
                  </li>
                </ul>
              </div>
              <div class="about-post-items">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <img src="@/assets/images/feature/feature-1.png" alt="title" />
                      <div class="trending-image-content">
                        <div class="post-meta">
                          <div class="meta-categories">
                            <a href="#">TECHNOLOGY</a>
                          </div>
                          <div class="meta-date">
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <h3 class="title">
                          <router-link to="/posts/postOne"
                            >Japans virus success has from puzzled the running
                            out?</router-link
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <img src="@/assets/images/feature/feature-2.png" alt="title" />
                      <div class="trending-image-content">
                        <div class="post-meta">
                          <div class="meta-categories">
                            <a href="#">TECHNOLOGY</a>
                          </div>
                          <div class="meta-date">
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <h3 class="title">
                          <router-link to="/posts/postOne"
                            >Japans virus success has from puzzled the running
                            out?</router-link
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <img src="@/assets/images/feature/feature-3.png" alt="title" />
                      <div class="trending-image-content">
                        <div class="post-meta">
                          <div class="meta-categories">
                            <a href="#">TECHNOLOGY</a>
                          </div>
                          <div class="meta-date">
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <h3 class="title">
                          <router-link to="/posts/postOne"
                            >Japans virus success has from puzzled the running
                            out?</router-link
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <img src="@/assets/images/feature/feature-4.png" alt="title" />
                      <div class="trending-image-content">
                        <div class="post-meta">
                          <div class="meta-categories">
                            <a href="#">TECHNOLOGY</a>
                          </div>
                          <div class="meta-date">
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <h3 class="title">
                          <router-link to="/posts/postOne"
                            >Japans virus success has from puzzled the running
                            out?</router-link
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <img src="@/assets/images/feature/feature-5.png" alt="title" />
                      <div class="trending-image-content">
                        <div class="post-meta">
                          <div class="meta-categories">
                            <a href="#">TECHNOLOGY</a>
                          </div>
                          <div class="meta-date">
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <h3 class="title">
                          <router-link to="/posts/postOne"
                            >Japans virus success has from puzzled the running
                            out?</router-link
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <img src="@/assets/images/feature/feature-6.png" alt="title" />
                      <div class="trending-image-content">
                        <div class="post-meta">
                          <div class="meta-categories">
                            <a href="#">TECHNOLOGY</a>
                          </div>
                          <div class="meta-date">
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <h3 class="title">
                          <router-link to="/posts/postOne"
                            >Japans virus success has from puzzled the running
                            out?</router-link
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <img src="@/assets/images/feature/feature-7.png" alt="title" />
                      <div class="trending-image-content">
                        <div class="post-meta">
                          <div class="meta-categories">
                            <a href="#">TECHNOLOGY</a>
                          </div>
                          <div class="meta-date">
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <h3 class="title">
                          <router-link to="/posts/postOne"
                            >Japans virus success has from puzzled the running
                            out?</router-link
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <img src="@/assets/images/feature/feature-8.png" alt="title" />
                      <div class="trending-image-content">
                        <div class="post-meta">
                          <div class="meta-categories">
                            <a href="#">TECHNOLOGY</a>
                          </div>
                          <div class="meta-date">
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <h3 class="title">
                          <router-link to="/posts/postOne"
                            >Japans virus success has from puzzled the running
                            out?</router-link
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <img src="@/assets/images/feature/feature-1.png" alt="title" />
                      <div class="trending-image-content">
                        <div class="post-meta">
                          <div class="meta-categories">
                            <a href="#">TECHNOLOGY</a>
                          </div>
                          <div class="meta-date">
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <h3 class="title">
                          <router-link to="/posts/postOne"
                            >Japans virus success has from puzzled the running
                            out?</router-link
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <img src="@/assets/images/feature/feature-2.png" alt="title" />
                      <div class="trending-image-content">
                        <div class="post-meta">
                          <div class="meta-categories">
                            <a href="#">TECHNOLOGY</a>
                          </div>
                          <div class="meta-date">
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <h3 class="title">
                          <router-link to="/posts/postOne"
                            >Japans virus success has from puzzled the running
                            out?</router-link
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <img src="@/assets/images/feature/feature-3.png" alt="title" />
                      <div class="trending-image-content">
                        <div class="post-meta">
                          <div class="meta-categories">
                            <a href="#">TECHNOLOGY</a>
                          </div>
                          <div class="meta-date">
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <h3 class="title">
                          <router-link to="/posts/postOne"
                            >Japans virus success has from puzzled the running
                            out?</router-link
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="trending-image-post feature-item mt-30">
                      <img src="@/assets/images/feature/feature-4.png" alt="title" />
                      <div class="trending-image-content">
                        <div class="post-meta">
                          <div class="meta-categories">
                            <a href="#">TECHNOLOGY</a>
                          </div>
                          <div class="meta-date">
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <h3 class="title">
                          <router-link to="/posts/postOne"
                            >Japans virus success has from puzzled the running
                            out?</router-link
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="pagination-item pt-40">
                      <nav aria-label="Page navigation example">
                        <ul class="pagination">
                          <li class="page-item active">
                            <a class="page-link" href="#">01</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">02</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">...</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">50</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true"
                                ><i class="fas fa-caret-right"></i
                              ></span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <style-one :datas="posts.slice(1, 6)" />
          </div>
        </div>
      </div>
    </section>
  </category-layout>
</template>

<script>
import StyleOne from "../Utility/Sidebar/StyleOne.vue";
import CategoryLayout from "./CategoryLayout.vue";
import Posts from "../Data/TrendingHomeThree";
export default {
  components: { CategoryLayout, StyleOne },
  data: () => ({
    posts: Posts.data,
  }),
};
</script>

<style></style>
