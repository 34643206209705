<template>
<div>
    <div class="container-fluid">
        <div class="row">
                    <div class="col-12" :class="weeklyNews.length!=0?'col-lg-6 col-md-6':'col-12'">
                        <FeaturedBroker />
                    </div>
                    <div class="col-lg-6 col-md-6 col-12" v-if="weeklyNews.length!=0" >
                        <WeekProgram title="What New This Week Program" :data="weeklyNews" :loader="loader" />
                    </div>

                    <div class="groupList col-12">
                        <GroupList />
                    </div>
                    <div class="col-12">
                        <div class="review-add-area text-center">
                           
                            <a v-if="!$store.state.promotions['middle-advertisement']" href="#">
                              <img src="https://placehold.co/1200x100" class="img-fluid w-100" alt="title" />
                          </a>
                          <a v-else :href="$store.state.promotions['middle-advertisement'].bonus_url" target="_blank" class="d-block" style="width:1200px;height:100px" >
                            <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['middle-advertisement'].banner" :alt="$store.state.promotions['middle-advertisement'].title">
                                    </a>
                        </div>
                    </div>
                    <div class="col-12">
                        <BrokerBonus />
                    </div>
                    <div class="col-lg-7 col-md-7 col-12">
                        <NewsTabs />
                    </div>

                    <div class="col-lg-5 col-md-5 col-12 mt-5">
                        <RightBar :datas="Posts" :tabs="sideBarTabs" />

                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <WeekProgram title="Draw Bonus" :data="drawNews" :loader="loader" />
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <WeekProgram title="FOREX BROKER PRESS RELEASES" :data="latestNews" :loader="loader" />
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <WeekProgram title="Top Brokers" :loader="brokerLoader" :data="brokers" />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 -col-12">
                        <ForexBonus />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 -col-12">
                        <FeaturedBroker />
                    </div>
                    <!-- ads banner  -->
                    <div class="col-lg-5 col-md-12 col-sm-12 col-12">
                        <div class="feature_broker home_ad post-international mt-40">
                            <div class="section-title">
                                <h3 class="title">Advertisement</h3>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="international-post-items">
                                        <div class="international-item">
                                            <div class="meta-category">
                                              <a v-if="!$store.state.promotions['box-advertisement-two']" href="#">
                                                <img src="https://placehold.co/450x405" class="img-fluid w-100" alt="title" />
                                            </a>
                                            <a v-else :href="$store.state.promotions['box-advertisement-two'].bonus_url" target="_blank" class="d-block" style="width:315px;height:250px" >
                                              <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['box-advertisement-two'].banner" :alt="$store.state.promotions['box-advertisement-two'].title">
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- end  -->
                    <div class="col-lg-2 col-md-12 col-sm-12 col-12">
                        <!-- <div class="feature_broker home_ad post-international mt-40">
                            <div class="section-title">
                                <h3 class="title">Sponsored By Broker</h3>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="international-post-items">
                                        <div class="international-item">
                                            <div class="meta-category">
                                                <img src="@/assets/images/ads/sponserAds.webp" alt="title" />
                                            </div>
                                            <div class="broker_content mt-3">

                                                <p>
                                                    RoboForex Ltd is a financial brokerage company regulated by the FSC, license No. 000138/437, reg. number 000001272. Address: 2118 Guava Street, Belama Phase 1, Belize City, Belize. Free No Deposit Bonus 30 USD Register and receive initial funds for trading. Receive Classic bonus up to 120% for your first and all other deposits that follow. 60% Profit Share Bonus Increase the deposited amount by 60% starting from your first deposit. Forex Cashback Rebates Bonus A great opportunity to have additional income on all account types.
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> -->
                    </div>
                    <!-- end  -->
                    <div class="col-lg-5 col-md-12 col-sm-12 col-12">
                        <div class="feature_broker home_ad post-international mt-40">
                            <div class="section-title">
                                <h3 class="title">Advertisement</h3>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="international-post-items">
                                        <div class="international-item">
                                            <div class="meta-category">
                                              <a v-if="!$store.state.promotions['box-advertisement-three']" href="#">
                                                <img src="https://placehold.co/450x405" class="img-fluid w-100" alt="title" />
                                            </a>
                                            <a v-else :href="$store.state.promotions['box-advertisement-three'].bonus_url" target="_blank" class="d-block" style="width:315px;height:250px" >
                                              <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['box-advertisement-three'].banner" :alt="$store.state.promotions['box-advertisement-three'].title">
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
        </div>
    </div>
    <others :sports="sports" :otherfinance="otherfinance" />

</div>
</template>

<script>
import trendingPost from "@/components/Data/HomeTwoPost";
import FeaturedBroker from "@/components/HomeTwo/FeaturedBroker.vue";
import WeekProgram from "@/components/HomeTwo/WeekProgram.vue";
import BrokerBonus from "@/components/HomeTwo/BrokerBonus.vue";
import ForexBonus from "@/components/HomeTwo/ForexBonus.vue";
import Others from "@/components/HomeTwo/Others.vue";
import GroupList from "@/components/HomeTwo/GroupList.vue";
import NewsTabs from "@/components/HomeTwo/NewsTabs.vue";
import RightBar from "@/components/Partials/RightBar.vue";
import Posts from "@/components/Data/NewsRowCard";

export default {
 
    components: {
        RightBar,
        FeaturedBroker,
        BrokerBonus,
        WeekProgram,
        ForexBonus,
        Others,
        GroupList,
        NewsTabs,
    },
    data() {
        return {
            sideBarTabs: [{
                    title: "Weekly Special",
                    value: 1,
                },
                {
                    title: "Sessional Bonus",
                    value: 2,
                },
                {
                    title: "Latest",
                    value: 3,
                },
            ],
            Posts: Posts.data,
            selected: 1,
            scrollTop: 0,
            sidebar: false,
            trendingPost: trendingPost.trending,
            featurePost: trendingPost.features,
            buisnessPost: trendingPost.buisness,
            mostViews: trendingPost.trending,
            finance: trendingPost.finance,
            videoNews: trendingPost.videoNews,
            morePost: trendingPost.morePost,
            sports: trendingPost.sports,
            otherfinance: trendingPost.otherfinance,
            loader: false,
            brokerLoader: false,
            weeklyNews: [],
            drawNews: [],
            latestNews: [],
            brokers: [],
            pagination: {
                grand_total: 0,
                current: 1,
                total: 1,
                perPage: 5,
            },
        }
    },
    mounted() {
        document.body.classList.add("home-3-bg");
        window.addEventListener('scroll', this.handleScroll);

    },
    beforeDestroy() {
        document.body.classList.remove("home-3-bg");
        window.removeEventListener('scroll', this.handleScroll);

    },
    created() {
        this.getWeeklyNews();
        this.getDrawBonus();
        this.getLatestNews();
        this.getTopBrokers();
        document.addEventListener("scroll", this.topToBottom);
    },
    watch: {
        // Watch the scrollTop property for changes
        scrollTop() {
            this.handleScroll(); 
        },
    },
    methods: {
        handleScroll() {
            // Update the scrollTop data property with the current scroll position
            // this.scrollTop = document.documentElement.scrollTop;
            // let currentTop = 206-this.scrollTop;
            // const ads= document.querySelector(".leftAds");
            // const adsRight= document.querySelector(".rightAds");
            // if(this.scrollTop<=206){
            //   ads.style.top=`${currentTop}px`
            //   adsRight.style.top=`${currentTop}px`
            // }
        },
        getTopBrokers() {
            this.brokerLoader = true;
            this.brokers = [];
            var data = {
                perPage: 10,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/broker/top", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.brokers = result.data.data; 
                    this.brokerLoader = false;
                })
                .catch((err) => {
                    this.brokerLoader = false;
                    if (err.response) {
                        console.log(err);
                    }
                });
        },
        getLatestNews() {
            this.loader = true;
            this.news = [];
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
            };
            this.$axios
                .get("/global/website/news/type/3", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.latestNews = result.data.data;
                    this.loader = false;
                })
                .catch((err) => {
                    this.loader = false;
                    if (err.response) {
                        console.log(err)
                    }

                });
        },
        getDrawBonus() {
            this.loader = true
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/news/contest", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.drawNews = result.data.data; 
                    this.loader = false
                })
                .catch((err) => {
                    this.loader = false
                    if (err.response) {
                        console.log(err)
                    }

                });
        },
        getWeeklyNews() {
            this.loader = true;
            this.weeklyNews = [];
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/news/weekly", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.weeklyNews = result.data.data;
                    this.pagination.current = result.data.meta.current_page;
                    this.pagination.total = result.data.meta.last_page;
                    this.pagination.grand_total = result.data.meta.total;
                    this.loader = false;
                })
                .catch((err) => {
                    this.loader = false;
                    if (err.response) {
                        console.log(err)
                    }

                });
        },
        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
        topToBottom() {
            const result = document.querySelector(".go-top");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.classList.add("active");
            } else {
                result.classList.remove("active");
            }
        },
    },
    metaInfo () {
    return {
      title: "FXNewsNow - Latest Forex & Crypto News, Bonuses, Broker Reviews",
      meta: [
      { charset: 'utf-8' },
      { name: 'description', content: "Stay updated with FXNewsNow for the latest forex and crypto news, no deposit bonuses, broker reviews, and trading guides." },
      { name: 'keyword', content: "forex news, crypto news, forex bonuses, broker reviews, trading guides"},
 
    ],
    }
  }, 
};
</script>

<style>
.home_ad .international-post-items {
    height: auto !important;
}
</style>
