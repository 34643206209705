<template>
  <div class="about-author-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="about-author-content">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                  Archive
                </li>
              </ol>
            </nav>          
            <div class="archive-content">
              <div class="row">
                <div class="col-lg-3 col-md-4">
                  <div class="archive-items">
                    <div class="section-title">
                      <h3 class="title">Archives: 2020</h3>
                    </div>
                    <div class="archive-list">
                      <ul>
                        <li><a href="#">January</a></li>
                        <li><a href="#">February</a></li>
                        <li><a href="#">March</a></li>
                        <li><a href="#">April</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-9 col-md-8">
                  <div class="archive-items border-left">
                    <div class="section-title">
                      <h3 class="title">Years list</h3>
                    </div>
                    <div class="archive-list">
                      <ul>
                        <li><a href="#">2000</a></li>
                        <li><a href="#">2001</a></li>
                        <li><a href="#">2002</a></li>
                        <li><a href="#">2003</a></li>
                        <li><a href="#">2004</a></li>
                        <li><a href="#">2005</a></li>
                      </ul>
                      <ul>
                        <li><a href="#">2006</a></li>
                        <li><a href="#">2007</a></li>
                        <li><a href="#">2008</a></li>
                        <li><a href="#">2009</a></li>
                        <li><a href="#">2010</a></li>
                        <li><a href="#">2011</a></li>
                      </ul>
                      <ul>
                        <li><a href="#">2012</a></li>
                        <li><a href="#">2013</a></li>
                        <li><a href="#">2014</a></li>
                        <li><a href="#">2015</a></li>
                        <li><a href="#">2016</a></li>
                        <li><a href="#">2017</a></li>
                      </ul>
                      <ul>
                        <li><a href="#">2018</a></li>
                        <li><a href="#">2019</a></li>
                        <li><a href="#">2020</a></li>
                        <li><a href="#">2021</a></li>
                        <li><a href="#">2022</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
