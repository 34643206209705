<template>
    <div>
        <div class="container-fluid">
            <div class="row"> 
                        <div class="col-lg-7">
                            <div class="post-layout-top-content">
                                <div
                                  class="
                                    post-categories
                                    d-flex
                                    justify-content-between
                                    align-content-center
                                  "
                                >
                                  <div class="categories-item">
                                    <span>{{category}}</span>
                                  </div>
                                  <div class="categories-share">
                                    <ul>
                                      <li><i class="fas fa-comment"></i>45020</li>
                                      <li><i class="fas fa-fire"></i>45020</li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="post-content">
                                  <h3 class="title">
                                    {{slug}}
                                  </h3>
                                  <p>
                                    The property, complete with 30-seat screening from room, a
                                    100-seat amphitheater and a swimming pond with sandy shower…
                                  </p>
                                  <div class="thumb">
                                    <img src="@/assets/images/post-layout.png" alt="title" />
                                  </div>
                                </div>
                                <div class="post-author">
                                  <div class="author-info">
                                    <div class="thumb">
                                      <img src="@/assets/images/author1.png" alt="title" />
                                    </div>
                                    <h5 class="title">Rafiqul islam</h5>
                                    <ul>
                                      <li>March 26, 2020</li>
                                      <li>Updated 1:58 p.m. ET</li>
                                    </ul>
                                  </div>
                                  <div class="author-social">
                                    <ul>
                                      <li>
                                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                                      </li>
                                      <li>
                                        <a href="#"><i class="fab fa-twitter"></i></a>
                                      </li>
                                      <li>
                                        <a href="#"><i class="fab fa-youtube"></i></a>
                                      </li>
                                      <li>
                                        <a href="#"><i class="fab fa-instagram"></i></a>
                                      </li>
                                      <li>
                                        <a href="#"><i class="fal fa-heart"></i></a>
                                      </li>
                                      <li>
                                        <a href="#"><i class="fal fa-bookmark"></i></a>
                                      </li>
                                      <li>
                                        <a href="#"><i class="fas fa-ellipsis-v"></i></a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="post-text mt-30">
                                  <p>
                                    Entilators will be taken from certain New York hospitals and
                                    redistributed to the worst-hit parts of the state under an
                                    order to be signed by Governor Andrew Cuomo.
                                  </p>
                                  <p>
                                    New York saw its highest single-day increase in deaths, up by
                                    562 to 2,935 - nearly half of all virus-related US deaths
                                    recorded yesterday. The White House may advise those in virus
                                    hotspots to wear face coverings in public to help stem the
                                    spread.
                                  </p>
                                  <p>The US now has 245,658 Covid-19 cases.</p>
                                  <p>
                                    A shortage of several hundred ventilators in New York City,
                                    the epicentre of the outbreak in the US, prompted Mr Cuomo to
                                    say that he will order the machines be taken from various
                                    parts of the state and give them to harder-hit areas.
                                  </p>
                                  <p>
                                    Amid a deepening crisis, top health official
                                    <span class="user">Dr Anthony Fauci</span> has said he
                                    believes all states should issue stay-at-home orders.
                                  </p>
                                  <p>
                                    “I don’t understand why that’s not happening,” Dr Fauci told
                                    CNN on Thursday. “If you look at what’s going on in this
                                    country, I just don’t understand why we’re not doing that.”
                                  </p>
                                  <p>
                                    “You’ve got to put your foot on the accelerator to bring that
                                    number down,” he added, referring to infection and death
                                    rates.
                                  </p>
                                  <ul>
                                    <li><a href="#">Should more of us wear face masks?</a></li>
                                    <li>
                                      <a href="#"
                                        ><u
                                          >Why some countries wear face masks and others don’t</u
                                        ></a
                                      >
                                    </li>
                                    <li>
                                      <a href="#">Coronavirus: Are homemade face masks safe?</a>
                                    </li>
                                  </ul>
                                  <p>
                                    The comments from Dr Fauci, who heads the National Institute
                                    of Allergy and Infectious Diseases, appeared to contradict
                                    those of President Trump, who has consistently dismissed the
                                    notion of a nationwide lockdown.
                                  </p>
                                  <p>
                                    “It’s awfully tough to say, ‘close it down.’ We have to have a
                                    little bit of flexibility,” Mr Trump said on Wednesday.
                                  </p>
                                </div>
                                <div class="post-text pt-20">
                                  <h5 class="title">What’s the debate over masks?</h5>
                                  <p>
                                    Both the US Centers for Disease Control (CDC) and the World
                                    Health Organization (WHO) are reassessing their guidance on
                                    face masks, as experts race to find ways to fight the highly
                                    contagious virus.
                                  </p>
                                  <p>
                                    Covid-19 is carried in airborne droplets from people coughing
                                    or sneezing, but there is some dispute over how far people
                                    should distance themselves from each other, and whether masks
                                    are useful when used by the public.
                                  </p>
                                  <div class="row pt-10">
                                    <div class="col-lg-6">
                                      <div class="post-thumb">
                                        <img src="@/assets/images/post-thumb-2.png" alt="title" />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="text">
                                        <p>
                                          The WHO advises that ordinary face masks are only
                                          effective if combined with careful hand-washing and
                                          social-distancing, and so far it does not recommend them
                                          generally for healthy people.
                                        </p>
                                        <p>
                                          However, more and more health experts now say there are
                                          benefits. They argue that the public use of masks can
                                          primarily help by preventing asymptomatic patients -
                                          people who have been infected with Covid-19 but are not
                                          aware, and not displaying any symptoms - from
                                          unknowingly spreading the virus to others.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="post-text pt-20">
                                  <p>
                                    Masks may also help lower the risk of individuals catching the
                                    virus through the droplets from another person’s sneeze or a
                                    cough - and people can be taught how put masks on and take
                                    them off correctly, they argue.
                                  </p>
                                  <p>
                                    On Thursday New York mayor Bill de Blasio urged all New
                                    Yorkers to cover their faces when outside and near others, but
                                    not to use surgical masks, which are in short supply.
                                  </p>
                                  <p>
                                    “It could be a scarf. It could be something you create
                                    yourself at home. It could be a bandana,” he said. Governor
                                    Cuomo weighed in on Friday, saying
                                    <span class="quote-text"
                                      >“i think it’s fair to say that the masks couldn’t hurt
                                      unless they gave you a false sense of security.”</span
                                    >
                                  </p>
                                  <p>
                                    Meanwhile, residents in Laredo, Texas will now face a $1,000
                                    (£816) fine if they fail to cover their noses and mouths while
                                    outside, after city officials issued an emergency ordinance to
                                    its approximately 250,000 residents this week.
                                  </p>
                                </div>
                                <div class="post-text pt-20">
                                  <h5 class="title">Which states are not in lockdown?</h5>
                                  <p>
                                    Both the US Centers for Disease Control (CDC) and the World
                                    Health Organization (WHO) are reassessing their guidance on
                                    face masks, as experts race to find ways to fight the highly
                                    contagious virus.
                                  </p>
                                  <p>
                                    Covid-19 is carried in airborne droplets from people coughing
                                    or sneezing, but there is some dispute over how far people
                                    should distance themselves from each other, and whether masks
                                    are useful when used by the public.
                                  </p>
                                  <div class="thumb pt-20 pb-35">
                                    <img src="@/assets/images/post-thumb-3.jpg" alt="title" />
                                    <span
                                      >I just had a baby - now I’m going to the frontline.</span
                                    >
                                  </div>
                                  <p>
                                    Masks may also help lower the risk of individuals catching the
                                    virus through the droplets from another person’s sneeze or a
                                    cough - and people can be taught how put masks on and take
                                    them off correctly, they argue.
                                  </p>
                                  <p>
                                    On Thursday New York mayor Bill de Blasio urged all New
                                    Yorkers to cover their faces when outside and near others, but
                                    not to use surgical masks, which are in short supply.
                                  </p>
                                  <p>
                                    Meanwhile, residents in Laredo, Texas will now face a $1,000
                                    (£816) fine if they fail to cover their noses and mouths while
                                    outside, after city officials issued an emergency ordinance to
                                    its approximately 250,000 residents this week.
                                  </p>
                                </div>
                                <div class="post-quote d-block d-md-flex align-items-center">
                                  <div class="thumb">
                                    <img src="@/assets/images/post-quote.jpg" alt="title" />
                                  </div>
                                  <div class="post-quote-content">
                                    <img src="@/assets/images/quote-icon.png" alt="title" />
                                    <p>
                                      I must explain to you how all this mistake idea denouncing
                                      pleasure and praising pain was born and I will give you a
                                      complete account of the system, and expound the actual
                                      teachings of the great explorer of the truth, the
                                      master-builder of human happiness. No one rejects, dislikes,
                                      or avoids pleasure because it is pleasure.
                                    </p>
                                    <div class="user">
                                      <img src="@/assets/images/author1.png" alt="title" />
                                      <h5 class="title">Rafiqul islam</h5>
                                      <span>Frontend Developer</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="post-text mt-35">
                                  <p>
                                    The next day I came back to my team and said, This is what I
                                    just heard, we have to get ready, he said. We knew that it
                                    wasn’t going to be long before we were going to have to deal
                                    with it.
                                  </p>
                                  <p>
                                    Mr. Hogan has also leaned on his wife, Yumi Hogan, a Korean
                                    immigrant, who was also at the governor’s convention, which
                                    included a dinner at the Korean ambassador’s home. As the
                                    first Korean first lady in American history, Ms. Hogan has
                                    become something of an icon in South Korea. I just grabbed my
                                    wife and said, Look, you speak Korean. You know the president.
                                    You know the first lady. You know the ambassador. Let’s talk
                                    to them in Korean, and tell them we need their help. Companies
                                    in South Korea said would tests.
                                  </p>
                                  <div class="thumb pt-10 pb-35">
                                    <img src="@/assets/images/post-thumb-4.png" alt="title" />
                                  </div>
                                  <p>
                                    In global terms the US has the most Covid-19 cases - more than
                                    245,000. And on Thursday the US authorities said more than
                                    1,000 had died in the past 24 hours - the highest daily toll
                                    so far in the world.
                                  </p>
                                  <p>
                                    Hospitals and morgues in New York are struggling to cope with
                                    the pandemic, and New York Governor Andrew Cuomo has warned
                                    that New York risks running out of ventilators for patients in
                                    six days.
                                  </p>
                                </div>
                             
                              </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-12 mt-5">
                            <RightBar :datas="Posts" :tabs="sideBarTabs" />
                        </div>
                   
            </div>
        </div>
 
    </div>
</template>

<script>
    import trendingPost from "@/components/Data/HomeTwoPost";
    import RightBar from "@/components/Partials/RightBar.vue";
    import Posts from "@/components/Data/NewsRowCard";

    export default {
        components: {
            RightBar,
        },
        
        data() {
            return {
                category:this.$route.params.category,
                slug:this.$route.params.id,
                sideBarTabs: [    {
        title: "Weekly Special",
        value: 1,
      },
      {
        title: "Sessional Bonus",
        value: 2,
      },
      {
        title: "Latest",
        value: 3,
      },
            ],
            Posts: Posts.data,
            selected: "latest",
            scrollTop: 0,
            sidebar: false,
            trendingPost: trendingPost.trending,
            featurePost: trendingPost.features,
            buisnessPost: trendingPost.buisness,
            mostViews: trendingPost.trending,
            finance: trendingPost.finance,
            videoNews: trendingPost.videoNews,
            morePost: trendingPost.morePost,
            sports: trendingPost.sports,
            otherfinance: trendingPost.otherfinance,
            };
        }, 
        mounted() {
            document.body.classList.add("home-3-bg");
            window.addEventListener('scroll', this.handleScroll);

        },
        beforeDestroy() {
            document.body.classList.remove("home-3-bg");
            window.removeEventListener('scroll', this.handleScroll);

        },
        created() {
            document.addEventListener("scroll", this.topToBottom);
        },
        watch: {
            $route(to) {
                this.category = to.params.category;
                this.slug = to.params.id;
            },
            scrollTop(newValue, oldValue) {
                // Do something with the new value or perform additional actions
                console.log('scrollTop changed:', newValue);
                console.log('scrollTop oldValue:', oldValue);

                this.handleScroll();
            },
        },
        methods: {
            handleScroll() {
                // Update the scrollTop data property with the current scroll position
                // this.scrollTop = document.documentElement.scrollTop;
                // let currentTop = 206-this.scrollTop;
                // const ads= document.querySelector(".leftAds");
                // const adsRight= document.querySelector(".rightAds");
                // if(this.scrollTop<=206){
                //   ads.style.top=`${currentTop}px`
                //   adsRight.style.top=`${currentTop}px`
                // }
            },
            toggleSidebar() {
                this.sidebar = !this.sidebar;
            },
            topToBottom() {
                const result = document.querySelector(".go-top");
                if (
                    document.body.scrollTop > window.innerHeight ||
                    document.documentElement.scrollTop > window.innerHeight
                ) {
                    result.classList.add("active");
                } else {
                    result.classList.remove("active");
                }
            },
        },
    };
</script>

<style>
    .home_ad .international-post-items {
        height: auto !important;
    }
</style>