<template>
<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 mt-5 brokerTopCard">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="brokerLogoWrapper text-center ">
                                    <div class="brokerProfileWrapper">
                                        <img v-if="!broker.profile" src="https://placehold.co/200x60" class="img-fluid rounded-lg" alt="title" />
                                        <img v-else :src="broker.profile" width="180px" height="60px" class="img-fluid rounded-lg" alt="title" />
                                    </div>
                                </div>
                                <div class="scoreBoard">
                                    <p class="scoreTitle">Score</p>
                                    <p class="totalScore">
                                        <span class="currentScoreMain">{{broker.score}}</span>
                                        <span class="currentScore"> /10</span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <h4 class="brokerName">
                                    {{broker.name}}
                                </h4>
                                <p class="headQuatar">
                                    {{broker?.country?.name}} | {{broker.foundation_year}}
                                </p>
                                <p class="regulator">
                                    <span v-for="(reg,index) in broker.regulations" :key="index">
                                        {{reg.name}} |</span>
                                </p>
                                <a :href="broker.live_url" target="_blank" class="visitBtn d-block w-full">Visit website</a>
                                <a :href="broker.demo_url" target="_blank" class="demoBtn d-block w-full">Open Demo Account</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">

                        <div class="verifyWrapper">
                            <p class="verifyTitle">
                                Licenses
                            </p>
                            <div class="statusCardWrapper text-center m-auto">
                                <div class="statusWrapper">
                                    <img v-if="broker.is_verified==0" src="@/assets/images/notApproved.jpg" alt="title">
                                    <img v-else src="@/assets/images/certigied.jpg" alt="title">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-12 px-0 mt-5">
                <div class="row">

                    <div class="col-lg-4 ">
                        <div class="card brokerContactCard">
                            <div class="card-header">Contact Number</div>
                            <div class="card-body">
                                {{broker.phone}}
                            </div>
                        </div>
                        <div class="card brokerContactCard mt-5">
                            <div class="card-header">Other ways of contact</div>
                            <div class="card-body">
                                {{broker.email}}
                            </div>
                        </div>
                        <div class="card brokerContactCard mt-5">
                            <div class="card-header">Broker Information</div>
                            <div class="card-body">
                                <div class="brokerInfoWrapperCard">
                                    <p class="brokerInfoTitle">Company name</p>
                                    <p class="brokerInfoTitle">{{broker.name}}</p>
                                    <hr>
                                </div>
                                <div class="brokerInfoWrapperCard">
                                    <p class="brokerInfoTitle">Regulated By</p>
                                    <p class="brokerInfoTitle"><span v-for="(reg,index) in broker.regulations" :key="index">
                                            {{reg.name}},
                                        </span></p>
                                    <hr>
                                </div>
                                <div class="brokerInfoWrapperCard">
                                    <p class="brokerInfoTitle">Platform registered country and Year</p>
                                    <p class="brokerInfoTitle">{{broker?.country?.name}} | {{broker.foundation_year}}</p>
                                    <hr>
                                </div>
                                <div class="brokerInfoWrapperCard">
                                    <p class="brokerInfoTitle">Company Website</p>
                                    <p class="brokerInfoTitle">{{broker.live_url}}</p>
                                    <hr>
                                </div>
                                <div class="brokerInfoWrapperCard">
                                    <p class="brokerInfoTitle">Base Currencies</p>
                                    <p class="brokerInfoTitle">{{broker.base_currencies}}</p>
                                    <hr>
                                </div>
                                <div class="brokerInfoWrapperCard">
                                    <p class="brokerInfoTitle">Languages</p>
                                    <p class="brokerInfoTitle">{{broker.languages}}</p>
                                    <hr>
                                </div>
                                <div class="brokerInfoWrapperCard">
                                    <p class="brokerInfoTitle">News Trading</p>
                                    <p class="brokerInfoTitle">{{ broker.news_trading==1 ? 'Yes' : 'No'}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-5"> 
                            <div class="card-body">
                            <h4 class="text-center">Check whenever you want</h4>
                            <h4 class="text-center text-secondary ">Download App for complete information:</h4>
                            <ul>
                                <li v-for="(link,index) in broker.links" :key="index" class="mb-2" >
                                    <a :href="link.link" target="_blank" rel="noopener noreferrer">{{link.name}}</a>
                                </li> 
                            </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="card brokerContactCard">
                            <div class="card-header">Account</div>
                            <div class="card-body">
                                <div class="tabs">
                                    <ul class="tabs__header">
                                        <li class="tabs__header-item" v-for="(tab,HeadIndex) in broker.accounts" :key="HeadIndex" @click="selectTab(HeadIndex)" :class="['nav-link', activeTab === HeadIndex ? 'active' : '']">
                                            
                                            <h6 class='m-0 p-0'>{{tab.title}}</h6>
                                        </li>
                                    </ul>
                                    <div class="tabs__container">
                                        <ul class="tabs__list" ref="tabsList">
                                            <li class="tabs__list-tab mt-3" v-for="(account,ConIndex) in broker.accounts" :key="ConIndex" v-bind:class="{active: activeTab == ConIndex}">
                                                <div class="row">
                                                    <div class="col-lg-12 tab_content_wrapper">
                                                        <div class="row">
                                                                <table class="table table-light ">
                                                                    <tbody class="row">
                                                                        <template>
                                                                            <tr class="col-6 bg-light">
                                                                                <td class=" w-100 d-block ">
                                                                                    <div class="d-flex justify-content-between">
                                                                                        <div>Benchmark</div> 
                                                                                        <div> {{ getValidData(account.benchmark) }} </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="col-6 bg-light">
                                                                                <td class=" w-100 d-block ">
                                                                                    <div class="d-flex justify-content-between">
                                                                                        <div>Currency</div>
                                                                                        <div>{{ getValidData(account.currency) }}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="col-6">
                                                                                <td class=" w-100 d-block ">
                                                                                    <div class="d-flex justify-content-between">
                                                                                        <div>Maximum Leverage </div>
                                                                                        <div>{{ getValidData(account.maximum_leverage) }}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="col-6">
                                                                                <td class=" w-100 d-block ">
                                                                                    <div class="d-flex justify-content-between">
                                                                                        <div>Minimum Position
                                                                                        </div>
                                                                                        <div>{{ getValidData(account.minimum_position) }}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="col-6 bg-light">
                                                                                <td class=" w-100 d-block ">
                                                                                    <div class="d-flex justify-content-between">
                                                                                        <div>Minimum Deposit</div>
                                                                                        <div >{{getValidData(account.minimum_deposit)}}</div>
                                                                                     
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="col-6 bg-light">
                                                                                <td class=" w-100 d-block ">
                                                                                    <div class="d-flex justify-content-between">
                                                                                        <div>Supported EA
                                                                                        </div>
                                                                                        <div>{{getValidData(account.supported_ea)}}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="col-6">
                                                                                <td class=" w-100 d-block ">
                                                                                    <div class="d-flex justify-content-between">
                                                                                        <div>Minimum Spread
                                                                                        </div>
                                                                                        <div>{{getValidData(account.minimum_spread)}}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="col-6">
                                                                                <td class=" w-100 d-block ">
                                                                                    <div class="d-flex justify-content-between">
                                                                                        <div>Depositing Method
                                                                                        </div>
                                                                                        <div>{{getValidData(account.depositing_method)}}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="col-6 bg-light">
                                                                                <td class=" w-100 d-block ">
                                                                                    <div class="d-flex justify-content-between">
                                                                                        <div>Products
                                                                                        </div>
                                                                                        <div>{{getValidData(account.products)}}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="col-6 bg-light">
                                                                                <td class=" w-100 d-block ">
                                                                                    <div class="d-flex justify-content-between">
                                                                                        <div>Withdrawal Method

                                                                                        </div>
                                                                                        <div>{{getValidData(account.withdrawal_method)}}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="col-6">
                                                                                <td class=" w-100 d-block ">
                                                                                    <div class="d-flex justify-content-between">
                                                                                        <div>Commission

                                                                                        </div>
                                                                                        <div>{{getValidData(account.commission)}}</div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </template>

                                                                    </tbody>

                                                                </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card companyProfile mt-5">
                            <div class="card-header">Company Summary</div>
                            <div class="card-body">
                                <div class="post-text mt-30" v-html="broker.description" >
                                 
                                </div>
                            </div>
                        </div>
                        <div class="card companyNews mt-5">
                            <div class="card-header">News</div>
                            <div class="card-body">
                                <template v-for="(item, index) in news">
                                    <router-link target="_blank" :to="'/news/details/'+item.slug" :key="index" class="gallery_item">
            
                                        <div class="gallery_item_thumb" style="width:100px">
                                            <img :src="item.image" alt="gallery" />
                                        </div>
                                        <div class="gallery_item_content">
                                            <div class="post-meta">
                                                <div class="meta-categories">
                                                    <router-link target="_blank" :to="'/news/details/'+item.slug" :key="index">{{ item.categories[0].name }}</router-link>
                                                </div>
                                                <div class="meta-date">
                                                    <span>{{ item.created_at }}</span>
                                                </div>
                                            </div>
                                            <h4 class="title">
                                                <router-link target="_blank" :to="'/news/details/'+item.slug" :key="index">{{ item.title }}</router-link>
                                            </h4>
                                        </div>
                                    </router-link>
                                    <!-- <row-card
                              :class="[darkClass && 'item' in darkClass ? darkClass.item : '']"
                              :category="true"
                              :datas="small"
                              :key="index"
                            /> -->
                                </template>
                            </div>
                        </div>

                        <div class="card companyProfile mt-5">
                            <div class="card-header">Reviews {{ broker.review.length }} </div>
                            <div class="card-body">
                                <div class="brokerReviewWrapper">
                                    <div v-for="(review,index) in broker.review" class="reviewItem" :key="index">
                                        <h4>{{review.name}}</h4>
                                        <p>
                                            {{review.review}}
                                        </p>
                                        <hr>
                                    </div>
                                    <!-- item end  -->
                                </div>
                            </div>
                        </div>
                        <div class="card companyProfile my-5">
                            <div class="card-header">Write a comment </div>
                            <div class="card-body">
                                <div class="brokerReviewAddWrapper">
                                    <form @submit.prevent="submit()">
                                        <div class="form-group">
                                            <label for="name">Your Name:</label>
                                            <input type="text" id="name" v-model="data.name" required class="form-control" placeholder="Your Name" />
                                        </div>
                                        <div class="form-group">
                                            <label for="email">Your Email:</label>
                                            <input type="email" id="email" v-model="data.email" required class="form-control" placeholder="Your Email" />
                                        </div>
                                        <div class="form-group">
                                            <label for="email">Your Rating:</label>
                                            <select class="form-control" v-model="data.rating" required id="rating">
                                                <option value="1">1 Star</option>
                                                <option value="2">2 Star</option>
                                                <option value="3">3 Star</option>
                                                <option value="4">4 Star</option>
                                                <option value="5">5 Star</option>
                                            </select>
                                        </div>

                                        <div class="form-group">
                                            <label for="review">Your Review:</label>
                                            <textarea id="review" class="form-control" required v-model="data.review" placeholder="write your review here"></textarea>
                                        </div>
                                        <div class="form-group">
                                            <button type="submit" class="btn btn-primary">Submit Review</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 
        </div>

    </div>
</div>
</template>

<script>
import trendingPost from "@/components/Data/HomeTwoPost";
import Posts from "@/components/Data/NewsRowCard";

export default {
    components: {},
    data() {
        return {
            loader: false,
            activeTab: 0,
            news:[],
         
            data: {
                name: null,
                email: null,
                broker_id: this.$route.params.slug,
                review: null,
                rating: null,
            },
            broker: null,
            slug: this.$route.params.slug,
            sideBarTabs: [{
                    title: "Weekly Special",
                    value: 1,
                },
                {
                    title: "Sessional Bonus",
                    value: 2,
                },
                {
                    title: "Latest",
                    value: 3,
                },
            ],
            Posts: Posts.data,
            selected: "latest",
            scrollTop: 0,
            sidebar: false,
            trendingPost: trendingPost.trending,
            featurePost: trendingPost.features,
            buisnessPost: trendingPost.buisness,
            mostViews: trendingPost.trending,
            finance: trendingPost.finance,
            videoNews: trendingPost.videoNews,
            morePost: trendingPost.morePost,
            sports: trendingPost.sports,
            otherfinance: trendingPost.otherfinance,
        };
    },
    mounted() {
        document.body.classList.add("home-3-bg");

    },
    beforeDestroy() {
        document.body.classList.remove("home-3-bg");

    },
    created() {
        this.getBroker()
        this.getNewsTypes()
        document.addEventListener("scroll", this.topToBottom);
    },
 
    methods: {
        getValidData(data){
            if(data==null || data== ''){
                return "--";
            }
            return data;
        
        },
        getNewsTypes() {
            this.news = [];
            var data = {
                perPage: 5,
                page: 1,
            };
            this.$axios
                .get("/global/website/news/type/3", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.news = result.data.data;
                })
                .catch((err) => {
                    if (err.response) {
                        console.log(err)
                    }

                });
        },
        selectTab(id) {
            let tabsList = this.$refs.tabsList;
            this.activeTab = id;
            console.log('current tab: ',id,'tabsList: ',tabsList,'activeTab: ',this.activeTab)

            tabsList.childNodes.forEach((tab, index) => {
                if (tab.classList.contains('active')) {
                    tab.classList.remove('active');
                    tab.classList.add('d-none');
                }
                if (index === id) {
                    tab.classList.add('active');
                    tab.classList.remove('d-none');
                }
            });

        },
        submit() {

            let fd = new FormData();
            for (const [key, value] of Object.entries(this.data)) {
                if (value !== null) {
                    fd.append(key, value);
                }
            }

            this.$axios
                .post("/global/website/review", fd, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    console.log(res)
                    this.$toast.open({
                        message: "Review Request Send Successfully",
                        type: 'success',
                    });
                    // push to the home page
                    this.$router.push('/');
                })
                .catch((error) => {
                    if (error.response) {
                        // object of errors
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            key;
                            this.$toast.open({
                                message: value[0],
                                type: 'error',
                            });
                        }
                    }
                });
        },
        getBroker() {
            this.$axios
                .get("/global/website/brokers/profile/" + this.$route.params.slug, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.broker = result.data.data;
                    this.loading = false
                    this.$meta().refresh();
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                        console.log(err)
                    }

                });
        },
        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
        topToBottom() {
            const result = document.querySelector(".go-top");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.classList.add("active");
            } else {
                result.classList.remove("active");
            }
        },
    },
    metaInfo () {
    return {
      title: "FXNewsNow - "+this.broker.name,
      meta: [
      { charset: 'utf-8' },
      { name: 'description', content: "Stay updated with FXNewsNow for the latest forex and crypto news, no deposit bonuses, broker reviews, and trading guides." },
      { name: 'keyword', content: "forex news, crypto news, forex bonuses, broker reviews, trading guides"},
      { property: 'og:image', content: this.broker.profile },
      { name: 'twitter:image', content: this.broker.profile },
    ],
    }
  }, 
};
</script>

<style>
.home_ad .international-post-items {
    height: auto !important;
}

.brokerTopCard {
    background: white !important;
    border: 1px solid #04345c42;
    padding: 20px;
    border-radius: 5px;
}

.statusWrapper {
    width: 170px;
    margin: auto !important;
}

.brokerProfileWrapper {
    width: 160px;
    margin: auto;
}

.brokerLogoWrapper {
    border: 1px solid #ddd;
    padding: 7px;
    border-radius: 5px;
}

.scoreBoard {
    border: 1px solid #ddd;
    padding: 7px;
    text-align: center;
    margin-top: 10px;
    border-radius: 5px;
    background: rgba(76, 109, 141, .05);
    cursor: pointer;
}

.currentScoreMain {
    color: #4c6d8d;
    font-size: 48px;
    font-weight: 400;
}

.currentScore {
    color: #4c6d8d;
    font-size: 17px;
}

.scoreTitle {
    color: #4c6d8d;
    font-weight: 400;
}

h4.brokerName {
    font-size: 28px;
}

p.headQuatar {
    font-size: 18px;
    font-weight: 400;
}

p.regulator {
    font-weight: 400;
}

a.visitBtn.d-block.w-full {
    background: #52a164;
    border-radius: 4px;
    padding: 10px 12px;
    color: white;
    font-weight: 400;
}

a.demoBtn.d-block.w-full {
    background: transparent;
    border-radius: 4px;
    padding: 10px 12px;
    color: #585757;
    font-weight: 400;
    border: 1px solid #4a484852;
    margin-top: 9px;
}

p.verifyTitle {
    font-weight: 500;
}
</style><style scoped>
.tabs__header-item.active {
    border-bottom: 2px solid #2b2b2b;

}

.tabs__header-item.active:hover {
    background: rgba(102, 189, 108, 0.1);

}

.groupItems {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, .2);
    padding: 10px;
    align-items: center;
}

.tabs {
    width: 100%;
    /* max-width: 400px; */
    margin: 0 auto;
}

.tabs__header {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tabs__header-item {
    flex: 1;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    transition: background 0.3s ease;
    position: relative;
}

.tabs__header-item:first-child {
    border-radius: 4px 0 0 0;
}

.tabs__header-item:last-child {
    border-radius: 0 4px 0 0;
    border-right: 0;
}

.tabs__header-item:hover {
    background: rgba(102, 189, 108, 0.1);
    border-bottom: 2px solid #2b2b2b;

}

.tabs__header-item:after {
    content: "";
    width: 0%;
    height: 1px;
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0%);
    /* background: #66bd6c; */
    transition: width 0.3s ease;
}

.tabs__header-item.active:after {
    width: 100%;
}

.tabs__header-item.active:before {
    background: #66bd6c;
}

.tabs__container {
    background: #fff;
    position: relative;
    /* overflow-y: hidden; */
}

.tabs__list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    transition: all 0.3s linear;
}

.tabs__list-tab.active {
    opacity: 1;
    display: block !important;

}

.tabs__list-tab {
    width: 100%;
    min-width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
    position: relative;
    transition: opacity 0.5s ease;
    opacity: 0;
    display: none !important;
}

.tab_content_wrapper p {
    color: var(--Gray-Scale-Gray-500, #808080);

    /* Body Small/Body Small, 400 */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 21px */
}

.tab_content_list {
    list-style: none;
}

.tab_content_list li {
    color: var(--Gray-Scale-Gray-500, #808080);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    margin-bottom: 10px;
}

.newsItems {
    display: flex;
    margin-bottom: 30px;
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 30px !important;
}

.newsItems .leftImg {
    width: 45%;
    margin-right: 20px;
}

.newsItems .leftImg img {
    width: 100% !important;
}
</style>
