<template>
    <div>
        <div class="brokerReviewWrapper my-5 row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="card mt-4">
                                <div class="card-header">
                                    <h4 class="card-title
                      ">Regulators Broker</h4>
                                </div>
                                <div class="card-body about-content">
                                    Navigating the forex market requires understanding regulatory frameworks. This guide explores key forex regulators around the world, providing insights into their roles, importance, and how they impact your trading choices. Stay informed and trade safely!  
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                         
                            <!-- Brokers List -->
                            <div class="card mt-5">
                                <div class="card-header">
                                    <h4 class="card-title text-center mb-0" >Regulators Brokers List</h4>
                                </div>
                                <div class="card-body about-content">
                                    <template  v-if="broLoader">
                       
                                        <div v-for="item in 5" :key="item" class="col-12 mb-2">
                                       <div class="row">
                                         <div class="col-3">
                                           <PuSkeleton width="50px"  height="50px">
                                           </PuSkeleton> 
                                         </div>
                                         <div class="col-9">
                                           <PuSkeleton v-for="item in 2" :key="item" height="10px">
                                           </PuSkeleton>
                                         </div> 
                                       </div>
                                        </div>
                                       </template>
                                       <template v-else>
                                        <table class="table table-light table-hover table-striped table-responsive-lg">
                                            <thead>
                                                <tr>
                                                    <th>Broker</th>
                                                    <th>Rating</th>
                                                    <th>Min Deposit</th>
                                                    <th width="140px">Regulation</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(broker,index) in brokers" :key="index" >
                                                    <td>
                                                    <router-link target="_blank" :to="'/brokers/profile/'+broker.id">
                                                              <div class="brokerThumb d-flex align-items-center">
                                                            <img :src="broker.profile" width="40px"  /> 
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    {{broker.name}}
                                                                </p> 
                                                        </div>
                                                        </router-link>
                                                    </td>
                                                    <td>{{broker.ratings_count}}</td>
                                                    <td>{{broker.min_deposit}}</td>
                                                    <td>
                                                        <span v-for="(regulation,index) in broker.regulations" :key="index" class="badge badge-primary mr-1">{{regulation.name}}</span> </td>
                                                    <td><a :href="broker.live_url" target="_blank" class='btn btn-sm btn-primary'>Open Account</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="col-lg-12">
                                            <div class="pagination-item">
                                              <pagination :edgeNavigation="true" v-model="pagination.current" :per-page="pagination.perPage" :records="pagination.grand_total" @paginate="getBrokers()"/>
                                            </div>
                                          </div>
                                    </template>
    
                                </div>
                            </div>
                        </div>
    
                        <!-- Right SideBar  -->
                        <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div class="featureBroker">
                                <FeaturedBroker :visit="false" />
                            </div>
                            <div class="adsSideBar mt-4 rounded">
                                <div class="card text-center p-2">
                                    <div class="international-post-items">
                                        <div class="international-item"> 
                                                    <a v-if="!$store.state.promotions['review-page-box-advertisement-one']" href="#">
                                                        <img src="https://placehold.co/360x325" class="img-fluid w-100" alt="title" />
                                                    </a>
                                                    <a v-else :href="$store.state.promotions['review-page-box-advertisement-one'].bonus_url" target="_blank" class="d-block" style="width:360px;height:325px" >
                                                      <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['review-page-box-advertisement-one'].banner" alt="title">
                                                    </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="drawBroker">
                                <WeekProgram title="Draw Bonus" :data="drawNews" :loader="loader" />
                            </div>
                            <div class="adsSideBar mt-4 rounded">
                                <div class="card text-center p-2">
                                    <div class="international-post-items">
                                        <div class="international-item">
                                            <a v-if="!$store.state.promotions['review-page-box-advertisement-two']" href="#">
                                                <img src="https://placehold.co/360x325" class="img-fluid w-100" alt="title" />
                                            </a>
                                            <a v-else :href="$store.state.promotions['review-page-box-advertisement-two'].bonus_url" target="_blank" class="d-block" style="width:360px;height:325px" >
                                              <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['review-page-box-advertisement-two'].banner" alt="title">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                 
         

        </div>

        <div class="review-add-area text-center mb-5">
         
            <a v-if="!$store.state.promotions['review-page-bottom-advertisement']" href="#">
                <img src="https://placehold.co/730x90" class="img-fluid w-100" alt="title" />
            </a>
            <a v-else :href="$store.state.promotions['review-page-bottom-advertisement'].bonus_url" target="_blank" style="width:730px;height:90px" >
              <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['review-page-bottom-advertisement'].banner" alt="title">
            </a>
        </div>
     
    </div>
</template>

<script>
    import FeaturedBroker from "@/components/HomeTwo/FeaturedBroker.vue";
    import WeekProgram from "@/components/HomeTwo/WeekProgram.vue";

    export default {
        components: {
            FeaturedBroker,
            WeekProgram
        },
        data() {
            return {
            drawNews: [],
            featureBrokers: [],
            brokers: [],
            sidebar: false,
            broLoader: false,
            loader: false,
            brokerLoader: false,
            pagination: {
                grand_total: 0,
                current: 1,
                total: 1,
                perPage: 10,
            },
            }
        }, 
        created() {
            document.addEventListener("scroll", this.topToBottom);
            this.getDrawBonus()
            this.getBrokers()
            this.getTopBrokers()
        },
        methods: {
            getBrokers() {
            this.broLoader = true;
            this.brokers = [];
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/brokers", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.brokers = result.data.data;
                    this.pagination.current = result.data.meta.current_page;
                    this.pagination.total = result.data.meta.last_page;
                    this.pagination.grand_total = result.data.meta.total;
                    this.broLoader = false;
                })
                .catch((err) => {
                    this.broLoader = false;
                    if (err.response) {
                        console.log(err);
                    }
                });
        },
            getTopBrokers() {
            this.brokerLoader = true;
            this.featureBrokers = [];
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/broker/feature", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.featureBrokers = result.data.data;
                    this.brokerLoader = false;
                })
                .catch((err) => {
                    this.brokerLoader = false;
                    if (err.response) {
                        console.log(err);
                    }
                });
        },
            getDrawBonus() {
            this.loader = true
            var data = {
                perPage:5,
                page: 1,
            };
            this.$axios
                .get("/global/website/news/contest", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.drawNews = result.data.data; 
                    this.loader = false
                })
                .catch((err) => {
                    this.loader = false
                    if (err.response) {
                        console.log(err)
                    }

                });
        },

            toggleSidebar() {
                this.sidebar = !this.sidebar;
            },
            topToBottom() {
                const result = document.querySelector(".go-top");
                if (
                    document.body.scrollTop > window.innerHeight ||
                    document.documentElement.scrollTop > window.innerHeight
                ) {
                    result.classList.add("active");
                } else {
                    result.classList.remove("active");
                }
            },
        },
    };
</script>

<style >

</style>