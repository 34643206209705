<template>
  <div>
    <about />
  </div>
</template>

<script>
import About from "../components/About/index.vue";
export default {
  components: { About },
};
</script>

<style></style>
