import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/magnific-popup.css'
import '../src/assets/css/default.css'
import '../src/assets/css/style.css'
import '../src/assets/css/custom.css'
import {store} from './store/index'
import axios from "axios";
import Pagination from 'vue-pagination-2';
Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL_BACKEND;
// axios.defaults.baseURL = `http://127.0.0.1:8000/api/v1`;
Vue.prototype.$axios = axios;
Vue.component('pagination', Pagination); 
import VueToast from 'vue-toast-notification';
import Skeleton from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css"
import 'vue-toast-notification/dist/theme-bootstrap.css';
Vue.use(VueToast, {
  // One of the options
  position: 'top-right'
})
//import 'vue-toast-notification/dist/theme-default.css';
Vue.use(Skeleton)
Vue.mixin({
  methods: {
    formatString(str) {
      return str
        .toLowerCase()
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  }
});
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
