<template>
    <div>
        <div class="brokerReviewWrapper my-5 row">
      
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="card mt-4">
                                <div class="card-header">
                                    <h4 class="card-title text-center
                      ">The Best Cryptocurrency Brokers for Trading Digital Currencies</h4>
                                </div>
                                <div class="card-body about-content">
                                    Forex Trading itself it’s difficult enough, to add another type of risk, is Broker Risk.
                                    The purpose of this research on FOREX BROKER REVIEWS is to ensure that you do not engage
                                    in Trading in a Fraudulent/Scam Forex Broker. We monitor, compare and analyze brokers to
                                    ensure that our study meets your expectation. We are committed to helping our readers
                                    pick the Best Forex Brokers.
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                         
                            <!-- Brokers List -->
                            <div class="card mt-5">
                                <div class="card-header">
                                    <h4 class="card-title text-center mb-0" >{{slug}} List</h4>
                                </div>
                                <div class="card-body about-content">
                                        <table class="table table-light table-hover table-striped table-responsive-lg">
                                            <thead>
                                                <tr>
                                                    <th>Broker</th>
                                                    <th>Rating</th>
                                                    <th>Min Deposit</th>
                                                    <th>Regulation</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    XM
                                                                </p> 
                                                        </div>
                                                    </td>
                                                    <td>4.5</td>
                                                    <td>$5</td>
                                                    <td>CySEC, ASIC, IFSC</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    HotForex
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$5</td>
                                                    <td>CySEC, FCA, FSCA</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    Exness
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$1</td>
                                                    <td>CySEC, FCA, FSCA</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    OctaFX
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$5</td>
                                                    <td>CySEC, FCA, FSCA</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    FP Markets
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$100</td>
                                                    <td>CySEC, ASIC</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    XM
                                                                </p> 
                                                        </div>
                                                    </td>
                                                    <td>4.5</td>
                                                    <td>$5</td>
                                                    <td>CySEC, ASIC, IFSC</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    HotForex
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$5</td>
                                                    <td>CySEC, FCA, FSCA</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    Exness
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$1</td>
                                                    <td>CySEC, FCA, FSCA</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    OctaFX
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$5</td>
                                                    <td>CySEC, FCA, FSCA</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    FP Markets
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$100</td>
                                                    <td>CySEC, ASIC</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    XM
                                                                </p> 
                                                        </div>
                                                    </td>
                                                    <td>4.5</td>
                                                    <td>$5</td>
                                                    <td>CySEC, ASIC, IFSC</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    HotForex
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$5</td>
                                                    <td>CySEC, FCA, FSCA</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    Exness
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$1</td>
                                                    <td>CySEC, FCA, FSCA</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    OctaFX
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$5</td>
                                                    <td>CySEC, FCA, FSCA</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    FP Markets
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$100</td>
                                                    <td>CySEC, ASIC</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    XM
                                                                </p> 
                                                        </div>
                                                    </td>
                                                    <td>4.5</td>
                                                    <td>$5</td>
                                                    <td>CySEC, ASIC, IFSC</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    HotForex
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$5</td>
                                                    <td>CySEC, FCA, FSCA</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    Exness
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$1</td>
                                                    <td>CySEC, FCA, FSCA</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    OctaFX
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$5</td>
                                                    <td>CySEC, FCA, FSCA</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="brokerThumb d-flex align-items-center">
                                                            <img src="@/assets/images/international-post-thumb.jpg" width="40px" />
                                                                <p class="brokerContent ml-2 mb-0">
                                                                    FP Markets
                                                                </p> 
                                                        </div>
                                                        </td>
                                                    <td>4.5</td>
                                                    <td>$100</td>
                                                    <td>CySEC, ASIC</td>
                                                    <td><button class='btn btn-sm btn-primary'>Open Account</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
    
                                </div>
                            </div>
                        </div>
    
                        <!-- Right SideBar  -->
                        <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div class="featureBroker">
                                <FeaturedBroker :visit="false" />
                            </div>
                            <div class="adsSideBar mt-4 rounded">
                                <div class="card text-center p-2">
                                    <div class="international-post-items">
                                        <div class="international-item">
                                                <img src="@/assets/images/ads/homeAds.webp" alt="title"
                                                    class="img-fluid w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="drawBroker">
                                <WeekProgram title="Draw Bonus" />
                            </div>
                            <div class="adsSideBar mt-4 rounded">
                                <div class="card text-center p-2">
                                    <div class="international-post-items">
                                        <div class="international-item">
                                                <img src="@/assets/images/ads/homeAds.webp" alt="title"
                                                    class="img-fluid w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
     
            </div>
 
        </div>

        <div class="review-add-area text-center mb-5">
            <a href="#">
                <img src="@/assets/images/ads/one_ad.png" alt="title" />
            </a>
        </div>
     
    </div>
</template>

<script>
    import FeaturedBroker from "@/components/HomeTwo/FeaturedBroker.vue";
    import WeekProgram from "@/components/HomeTwo/WeekProgram.vue";

    export default {
        components: {
            FeaturedBroker,
            WeekProgram
        },
        data() {
            return {
                slug:this.$route.params.slug,
                sidebar: false,
            };
        },
        watch: {
            $route(to) {
                this.slug = to.params.slug;
            },
        },
        created() {
            document.addEventListener("scroll", this.topToBottom);
        },
        methods: {
            toggleSidebar() {
                this.sidebar = !this.sidebar;
            },
            topToBottom() {
                const result = document.querySelector(".go-top");
                if (
                    document.body.scrollTop > window.innerHeight ||
                    document.documentElement.scrollTop > window.innerHeight
                ) {
                    result.classList.add("active");
                } else {
                    result.classList.remove("active");
                }
            },
        },
    };
</script>

<style >

</style>