<template>
<div>
    <div class="brokerReviewWrapper my-5 row">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="card mt-4">
                <div class="card-header">
                    <h4 class="card-title
                      ">The Best Forex Brokers in {{formatString(slug)}}</h4>
                </div>
                <div class="card-body about-content">
                {{content}}
                </div>
             
            </div>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-12 col-12">

            <!-- Brokers List -->
            <div class="card mt-5">

                <div class="card-body about-content">
                    <template v-if="loader">

                        <div v-for="item in 5" :key="item" class="col-12 mb-2">
                            <div class="row">
                                <div class="col-3">
                                    <PuSkeleton width="50px" height="50px">
                                    </PuSkeleton>
                                </div>
                                <div class="col-9">
                                    <PuSkeleton v-for="item in 2" :key="item" height="10px">
                                    </PuSkeleton>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <table class="table table-light table-hover table-striped table-responsive-lg">
                            <thead>
                                <tr>
                                    <th>Broker</th>
                                    <th>Rating</th>
                                    <th>Min Deposit</th>
                                    <th width="120px">Regulation</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(broker,index) in brokers" :key="index">
                                    <td>
                                        <router-link target="_blank" :to="'/brokers/profile/'+broker.id">
                                            <div class="brokerThumb d-flex align-items-center">
                                                <img :src="broker.profile" width="40px" />
                                                <p class="brokerContent ml-2 mb-0">
                                                    {{broker.name}}
                                                </p>
                                            </div>
                                        </router-link>
                                    </td>
                                    <td>{{broker.ratings_count}}</td>
                                    <td>{{broker.min_deposit}}</td>
                                    <td>
                                        <span v-for="(regulation,index) in broker.regulations" :key="index" class="badge badge-primary mr-1">{{regulation.name}}</span> </td>
                                    <td><a :href="broker.live_url" target="_blank" class='btn btn-sm btn-primary'>Open Account</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-lg-12">
                            <div class="pagination-item">
                                <pagination :edgeNavigation="true" v-model="pagination.current" :per-page="pagination.perPage" :records="pagination.grand_total" @paginate="getBroker(slug)" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <!-- Right SideBar  -->
        <div class="col-lg-5 col-md-6 col-12 mt-5">
            <RightBar :tabs="sideBarTabs" />
        </div>

    </div>
    <div class="review-add-area text-center mb-5">

        <a v-if="!$store.state.promotions['review-page-bottom-advertisement']" href="#">
            <img src="https://placehold.co/730x90" class="img-fluid w-100" alt="title" />
        </a>
        <a v-else :href="$store.state.promotions['review-page-bottom-advertisement'].bonus_url" target="_blank" style="width:730px;height:90px">
            <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['review-page-bottom-advertisement'].banner" alt="title">
        </a>
    </div>
</div>
</template>

<script>
import RightBar from "@/components/Partials/RightBar.vue";

export default {
    metaInfo () {
    return {
      title: "Best Forex Brokers in the "+this.formatString(this.$route.params.country)+" - FXNewsNow",
      meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Compare and find the best forex brokers in various regions with FXNewsNow’s expert recommendations.' },
      { name: 'keyword', content: 'best forex brokers, top forex brokers, forex trading' },
    ],
    }
  },
    components: {
        RightBar
    },
    data() {
        return {
            sideBarTabs: [{
                    title: "Weekly Special",
                    value: 1,
                },
                {
                    title: "Sessional Bonus",
                    value: 2,
                },
                {
                    title: "Latest",
                    value: 3,
                },
            ],
            sidebar: false,
            slug: this.$route.params.country,
            content: '',
            brokers: [],
            loader: false,
            pagination: {
                grand_total: 0,
                current: 1,
                total: 1,
                perPage: 10,
            },
        };
    },
    watch: {
        $route(to) {
            this.slug = to.params.country;
            this.getBroker(this.slug);
            this.contentUpdate(this.slug);

        },
    },
    created() {
        document.addEventListener("scroll", this.topToBottom);
        this.getBroker(this.slug);
        this.contentUpdate(this.slug);
    },
    methods: { 
        contentUpdate(slug){
            switch(slug){
                case 'middle-east':
                    this.content = 'Explore the best brokers in the Middle East, where a booming market meets innovative trading solutions. Whether you’re a seasoned trader or just starting, discover platforms that offer competitive spreads, local support, and a range of trading instruments tailored for the region.';
                    break;
                case 'europe':
                    this.content = 'Dive into the vibrant European trading landscape with our curated list of the best brokers. From robust regulatory frameworks to diverse trading options, find the perfect partner to elevate your trading experience in one of the world’s most dynamic markets.';
                    break;
                case 'uk':
                    this.content = 'The UK is home to some of the most reputable brokers in the industry. Our guide highlights the best UK brokers that offer excellent customer service, innovative trading platforms, and competitive fees, ensuring you have the tools to succeed in the forex market.';
                    break;
                case 'malaysia':
                    this.content = 'Malaysia’s financial landscape is thriving, and with it comes a range of trading opportunities. Discover the best brokers in Malaysia that provide tailored solutions for local traders, featuring easy access to global markets, competitive pricing, and exceptional support.';
                    break;
                case 'thailand':
                    this.content = 'Thailand is rapidly becoming a trading hub in Southeast Asia. Our selection of the best brokers in Thailand showcases platforms that cater to local traders with competitive spreads, user-friendly interfaces, and a wide array of trading instruments.';
                    break;
                case 'south-america':
                    this.content = 'Uncover the potential of South American markets with our top broker recommendations. Tailored to the unique needs of traders in the region, these brokers offer comprehensive services, local support, and diverse trading options to help you thrive.';
                    break;
                case 'indonesia':
                    this.content = "Indonesia's growing economy presents exciting trading opportunities. Our guide to the best brokers in Indonesia highlights platforms that provide competitive fees, robust trading tools, and dedicated customer service to support your trading journey.";
                    break;
                case 'south-africa':
                    this.content = 'Navigate the South African forex landscape with our selection of the best brokers. Designed to meet the needs of local traders, these brokers offer strong regulatory oversight, excellent trading conditions, and resources to help you maximize your trading potential.';
                    break;
                case 'cdf':
                    this.content = "CFDs offer flexibility and opportunities in various markets. Our list of the best CFD brokers highlights platforms that provide a wide range of instruments, low fees, and user-friendly tools to help you capitalize on price movements effectively.";
                    break;
                case 'ecn':
                    this.content = 'Experience the advantages of direct market access with our top ECN brokers. These platforms offer low spreads, high liquidity, and fast execution, making them ideal for serious traders looking to enhance their trading strategies.';
                    break;
                case 'stp':
                    this.content = 'Discover the benefits of Straight Through Processing (STP) with our recommended brokers. Enjoy competitive spreads, reduced slippage, and transparency in trading, ensuring a smooth experience for all your forex transactions.';
                    break;
                case 'mt5':
                    this.content = 'The MetaTrader 5 platform offers advanced features for traders. Explore our list of the best MT5 brokers that provide comprehensive tools, multiple asset classes, and enhanced trading functionalities to support your trading strategy.';
                    break;
                case 'mt4':
                    this.content = 'MetaTrader 4 remains a favorite among traders worldwide. Our guide to the best MT4 brokers highlights platforms that offer powerful tools, automated trading options, and exceptional customer support to help you navigate the forex market.';
                    break;
                case 'lowest-spread':
                    this.content = 'Maximizing profits starts with minimizing costs. Our selection of the lowest spread forex brokers ensures you can trade efficiently, keeping your expenses down while accessing top-tier trading platforms and resources.';
                    break;
                case 'crypto-brokers':
                    this.content = 'Dive into the world of cryptocurrency with confidence! Our list of the best crypto brokers highlights platforms that offer secure trading, a wide selection of digital assets, and cutting-edge tools to help you navigate this exciting market.';
                    break;
                default:
                    this.content = 'Navigating the forex market requires understanding regulatory frameworks. This guide explores key forex regulators around the world, providing insights into their roles, importance, and how they impact your trading choices. Stay informed and trade safely!  ';
            }
        },
        getBroker(slug) {
            this.loader = true;
            this.brokers = [];
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/broker/bycountry/" + slug, {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.brokers = result.data.data;
                    this.pagination.current = result.data.meta.current_page;
                    this.pagination.total = result.data.meta.last_page;
                    this.pagination.grand_total = result.data.meta.total;
                    this.loader = false;
                })
                .catch((err) => {
                    this.loader = false;
                    if (err.response) {
                        console.log(err);
                    }
                });
        },
        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
        topToBottom() {
            const result = document.querySelector(".go-top");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.classList.add("active");
            } else {
                result.classList.remove("active");
            }
        },
    },
};
</script>

<style >

</style>
