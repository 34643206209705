<template>
    <div>

        <div class="aboutWrapper">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="card mt-4">
                            <div class="card-header">
                                <h4 class="card-title
">TERMS OF FXDAILYNEWS</h4>
                            </div>
                            <div class="card-body about-content">
                                <div v-html="page.content"></div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div class="add-area text-center my-5 pb-5">
            <a href="#" class="mb-5">
                <img src="@/assets/images/ads/one_ad.png" alt="title" />
            </a>
        </div> 
    </div>
</template>

<script>
    export default {
        data() {
            return {
                page:null,
                sidebar: false,
            }
        },
        created() {
            this.getPage()
            document.addEventListener("scroll", this.topToBottom);
        },
        methods: {
            getPage(){
                this.$axios
                .get("/global/website/page/terms-conditions", {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.page=result.data.data;
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                     console.log(err)
                    }

                });
            },
            toggleSidebar() {
                this.sidebar = !this.sidebar;
            },
            topToBottom() {
                const result = document.querySelector(".go-top");
                if (
                    document.body.scrollTop > window.innerHeight ||
                    document.documentElement.scrollTop > window.innerHeight
                ) {
                    result.classList.add("active");
                } else {
                    result.classList.remove("active");
                }
            },
        },
    };
</script>

<style>

</style>