<template>
  <div>
    <footer class="footer-area">
      <div class="container">
       
        <div class="footer-widget-area">
          <div class="row">
            <div class="col-lg-8">
              <div class="footer-widget-right-border">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="widget widget-list">
                      <div class="section-title section-title-2">
                        <h3 class="title">FOREX BROKERS</h3>
                      </div>
                      <div class="list d-flex justify-content-between">
                        <ul>
                          <li><a href="#">Politics</a></li>
                          <li><a href="#">Business</a></li>
                          <li><a href="#">Technology</a></li>
                          <li><a href="#">Science</a></li>
                          <li><a href="#">Health</a></li>
                          <li><a href="#">Sports</a></li>
                          <li><a href="#">Entertainment</a></li>
                        </ul>
                        <ul>
                          <li><a href="#">Education</a></li>
                          <li><a href="#">Obituaries</a></li>
                          <li><a href="#">Corrections</a></li>
                          <li><a href="#">Education</a></li>
                          <li><a href="#">Today’s Paper</a></li>
                          <li><a href="#">Corrections</a></li>
                          <li><a href="#">Foods</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="widget widget-list widget-list-2">
                      <div class="section-title section-title-2">
                        <h3 class="title">BR BY DEPOSIT</h3>
                      </div>
                      <div class="list d-flex justify-content-between">
                        <ul>
                          <li><a href="#">Crossword</a></li>
                          <li><a href="#">Food</a></li>
                          <li><a href="#">Automobiles</a></li>
                          <li><a href="#">Education</a></li>
                          <li><a href="#">Health</a></li>
                          <li><a href="#">Magazine</a></li>
                          <li><a href="#">Weddings</a></li>
                        </ul>
                        <ul>
                          <li><a href="#">Classifieds</a></li>
                          <li><a href="#">Photographies</a></li>
                          <li><a href="#">NYT Store</a></li>
                          <li><a href="#">Journalisms</a></li>
                          <li><a href="#">Public Editor</a></li>
                          <li><a href="#">Tools & Services</a></li>
                          <li><a href="#">My Account</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="footer-twitter-post">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div class="widget widget-list">
                        <div class="section-title section-title-2">
                          <h3 class="title">FOREX BONUS</h3>
                        </div>
                        <div class="list">
                          <ul>
                            <li><a href="#">Today’s Opinion</a></li>
                            <li><a href="#">Op-Ed Contributing</a></li>
                            <li><a href="#">Contributing Writers</a></li>
                            <li><a href="#">Business News</a></li>
                            <li><a href="#">Collections</a></li>
                            <li><a href="#">Today’s Paper</a></li>
                            <li><a href="#">Saturday Review</a></li>
                            <li><a href="#">Product Review</a></li>
                          </ul>
                        </div>
                      </div>
                    </div> 
                    <div class="col-lg-6 col-md-6">
                      <div class="widget widget-list widget-list-2">
                        <div class="section-title section-title-2">
                          <h3 class="title">FOREX AWARDS</h3>
                        </div>
                        <div class="list d-flex justify-content-between">
                          <ul>
                            <li><a href="#">Crossword</a></li>
                            <li><a href="#">Food</a></li>
                            <li><a href="#">Automobiles</a></li>
                            <li><a href="#">Education</a></li>
                            <li><a href="#">Health</a></li>
                            <li><a href="#">Magazine</a></li>
                            <li><a href="#">Weddings</a></li>
                          </ul>
                          <ul>
                            <li><a href="#">Classifieds</a></li>
                            <li><a href="#">Photographies</a></li>
                            <li><a href="#">NYT Store</a></li>
                            <li><a href="#">Journalisms</a></li>
                            <li><a href="#">Public Editor</a></li>
                            <li><a href="#">Tools & Services</a></li>
                            <li><a href="#">My Account</a></li>
                          </ul>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="footer-rightbar mt-60">
                <div class="row">
                  <div class="col-lg-12 col-md-6">
                    <div class="widget widget-news">
                      <div class="section-title section-title-2">
                        <h3 class="title">News categories</h3>
                      </div>
                      <div class="footer-news">
                        <div
                          class="
                            gallery_item gallery_item-style-2
                            footer_gallery_item
                          "
                        >
                          <div class="gallery_item_content">
                            <div class="post-meta">
                              <div class="meta-categories">
                                <a href="#" tabindex="0">TECHNOLOGY</a>
                              </div>
                              <div class="meta-date">
                                <span>March 26, 2020</span>
                              </div>
                            </div>
                            <h4 class="title">
                              <a href="#" tabindex="0"
                                >Nancy zhang a chinese busy woman and dhaka</a
                              >
                            </h4>
                            <span>1</span>
                          </div>
                        </div>
                        <div
                          class="
                            gallery_item gallery_item-style-2
                            footer_gallery_item
                          "
                        >
                          <div class="gallery_item_content">
                            <div class="post-meta">
                              <div class="meta-categories">
                                <a href="#" tabindex="0">TECHNOLOGY</a>
                              </div>
                              <div class="meta-date">
                                <span>March 26, 2020</span>
                              </div>
                            </div>
                            <h4 class="title">
                              <a href="#" tabindex="0"
                                >Success is not a good teacher failure makes you
                                humble</a
                              >
                            </h4>
                            <span>2</span>
                          </div>
                        </div>
                        <div
                          class="
                            gallery_item gallery_item-style-2
                            footer_gallery_item
                          "
                        >
                          <div class="gallery_item_content">
                            <div class="post-meta">
                              <div class="meta-categories">
                                <a href="#" tabindex="0">TECHNOLOGY</a>
                              </div>
                              <div class="meta-date">
                                <span>March 26, 2020</span>
                              </div>
                            </div>
                            <h4 class="title">
                              <a href="#" tabindex="0"
                                >Cheap smartphone sensor could help you old food
                                safe</a
                              >
                            </h4>
                            <span>3</span>
                          </div>
                        </div>
                        <div
                          class="
                            gallery_item gallery_item-style-2
                            footer_gallery_item
                          "
                        >
                          <div class="gallery_item_content">
                            <div class="post-meta">
                              <div class="meta-categories">
                                <a href="#" tabindex="0">TECHNOLOGY</a>
                              </div>
                              <div class="meta-date">
                                <span>March 26, 2020</span>
                              </div>
                            </div>
                            <h4 class="title">
                              <a href="#" tabindex="0"
                                >Class property employ ancho red multi humble</a
                              >
                            </h4>
                            <span>4</span>
                          </div>
                        </div>
                        <div
                          class="
                            gallery_item gallery_item-style-2
                            footer_gallery_item
                          "
                        >
                          <div class="gallery_item_content">
                            <div class="post-meta">
                              <div class="meta-categories">
                                <a href="#" tabindex="0">TECHNOLOGY</a>
                              </div>
                              <div class="meta-date">
                                <span>March 26, 2020</span>
                              </div>
                            </div>
                            <h4 class="title">
                              <a href="#" tabindex="0"
                                >Best garden wing supplies for the horticu
                                ltural</a
                              >
                            </h4>
                            <span>5</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                   
                </div>
              </div>
            </div>
            <div class="col-12">
              <h3 class="title text-white text-center">DISCLAIMER</h3>
              <p class="text-center text-light">
                Forex Trading, CFDs Trading, Crypto currencies Trading and Binary Options Trading roll up a high degree of risk. FxNewsNow published information collected from many broker houses and believe it is correct but we could not give any guarantee about their Bonus No Deposit Bonus, Contest and any promotion, information. We work regular to collect information of broker houses according to brokers latest data. If you think any information & data is unbelievable and incorrect please contact with relevant broker house
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="footer-copyright">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div
              class="
                copyright-item
                d-block d-md-flex
                justify-content-between
                align-items-center
              "
            >
              <p>© Copyright 2024, All Rights Reserved</p>
              <ul>
                <li><a href="#">About</a></li>
                <li><a href="#">Advertise</a></li>
                <li><a href="#">Privacy & Policy</a></li>
                <li><a href="#">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
