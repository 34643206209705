<template>
  <div>
    <div class="mt-5">
      <Form />
    </div>
    <div class=" text-center mb-5">
        <div class="review-add-area text-center">
                           
          <a v-if="!$store.state.promotions['middle-advertisement']" href="#">
            <img src="https://placehold.co/1200x100" class="img-fluid w-100" alt="title" />
        </a>
        <a v-else :href="$store.state.promotions['middle-advertisement'].bonus_url" target="_blank" class="d-block" style="width:1200px;height:100px" >
          <img style="object-fit: fill;width:100%;height:100%" :src="$store.state.promotions['middle-advertisement'].banner" :alt="$store.state.promotions['middle-advertisement'].title">
                  </a>
      </div>
    </div>
 
  </div>
</template>

<script>
import Form from "./Form.vue";
export default {
  components: {  Form },
  data: () => ({
    sidebar: false,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style></style>
