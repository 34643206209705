<template>
  <div>
    <div class="container top-ten-broker-main">
      <div class="row w-full w-100">
        <div class="col-12">
          <h4 class="text-center top-ten-broker-head">
            Trade With TOP TEN Brokers
          </h4>
        </div>
        <div class="col-12 d-block">
          <div class="top-ten-broker-main">
            <div class="top-ten-broker-block" v-for="(broker,key) in brokers" :key="key" >
              <div class="top-ten-broker-img">
                <router-link target="_blank" :to="'brokers/profile/'+broker.id">
                  <img
                    width="155"
                    height="74"
                    class="img-fluid"
                    :src="broker.cover"
                    alt="brand"
                  />
                  <!-- src="https://placehold.co/155x74" -->
                </router-link>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      brokerLoader: false,
      brokers: [],
    };
  },
  created() {
    this.getTopBrokers();
  },
  methods: {
    getTopBrokers() {
      this.brokerLoader = true;
      this.brokers = [];
      var data = {
        perPage: 10,
        page: 1,
      };
      this.$axios
        .get("/global/website/broker/top", {
          params: data,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          this.brokers = result.data.data;
          this.brokerLoader = false;
        })
        .catch((err) => {
          this.brokerLoader = false;
          if (err.response) {
            console.log(err);
          }
        });
    },
  },
};
</script>
<style>
.top-ten-broker-img a{
    width:100%;
    height: 52px;
    display: block;
}
.top-ten-broker-img a img{
    width:100%;
    height:100%;
    object-fit: fill;
}
/* .top-ten-broker-main is main wrapper under this has many .top-ten-broker-block now flex this and max show 5 in one row */
.top-ten-broker-main {
  display: flex;
  flex-wrap: wrap;
}

.top-ten-broker-block {
  flex: 0 0 18%;
  max-width: 20%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}
.top-ten-broker-img {
  width: 100% !important;
  text-align: center;
}
</style>
