<template lang="">
  <div class="feature_broker week_program post-international mt-40">
    <div class="section-title">
      <h3 class="title">Forex Bonus, News, Analysis, Articles</h3>
    </div>
    <template v-if="newsBonusLoader">
      <div v-for="item in 5" :key="item" class="row mt-3 mb-2 px-2">
        <div class="col-6" v-for="item in 2" :key="item">
            <div class="row">
                <div :key="item" class="col-3">
                    <PuSkeleton width="80px" height="10px">
                    </PuSkeleton>
                </div>
            </div>
            <PuSkeleton :count="2" height="10px">
            </PuSkeleton>
        </div>
    </div>
  </template>
  <template v-if="newsBonusLoader==false">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="international-post-items">
          <div class="international-item" v-for="(news,index) in latestNews" :key="index" >
            <div class="meta-category"><span>{{news.categories[0].name}}</span></div>
            <div class="text-thumb d-flex align-items-center">
              <div style="width:40px;height:40px" class="thumb"> 
                <img :src="news.image" alt="title" class="w-100 h-100" /> 
              </div>
              <div class="broker_content">
                <p>
                  <router-link target="_blank" :to="'news/details/'+news.slug">
                    {{news.title}}
                  </router-link>
                </p>
              </div>
            </div>
          </div> 
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="international-post-items">
          <div class="international-item" v-for="(news,index) in featureBonus" :key="index" >
            <div class="meta-category"><span>{{news.categories[0].name}}</span></div>
            <div class="text-thumb d-flex align-items-center">
              <div style="width:40px;height:40px" class="thumb"> 
                <img :src="news.image" alt="title" class="w-100 h-100" /> 
              </div>
              <div class="broker_content">
                <p>
                  <router-link target="_blank" :to="'news/details/'+news.slug">
                    {{news.title}}
                  </router-link>
                </p>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </template>

  </div>
</template>

<script>
export default {
  data() {
    return {
      newsBonusLoader:false,
      latestNews: [],
      featureBonus: [],
      pagination: {
                grand_total: 0,
                current: 1,
                total: 1,
                perPage: 3,
            },
    }
  },
  created() {
    this.getLatestNews();
    this.getFeatureBonus();
    
  },
  methods: {
    getLatestNews() {
      this.newsBonusLoader = true;
            this.latestNews = [];
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
            };
            this.$axios
                .get("/global/website/news/type/3", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.latestNews = result.data.data;
                    this.newsBonusLoader = false;
                })
                .catch((err) => {
                    this.newsBonusLoader = false;
                    if (err.response) {
                        console.log(err)
                    }

                });
    },
    getFeatureBonus() {
            this.newsBonusLoader = true;
            this.news = [];
            var data = {
                perPage: this.pagination.perPage,
                page: this.pagination.current,
                searchText: this.search,
            };
            this.$axios
                .get("/global/website/news/type/4", {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => { 
                    this.featureBonus = result.data.data; 
                    this.newsBonusLoader = false;
                })
                .catch((err) => {
                    this.newsBonusLoader = false;
                    if (err.response) {
                        console.log(err)
                    }

                });
        },
  },
};
</script>

<style>
.feature_broker .section-title {
  text-align: center;
  padding: 15px 0px;
  background: #0f91ff;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.feature_broker h3.title {
  color: white;
}

.button_wrapper {
  text-align: center;
  display: block;
  justify-content: space-between;
}

.broker_content {
    width:100%;
    padding-left: 20px;
}

.button_wrapper button {
  width: 100%;
  border-radius: 0px;
  text-transform: uppercase;
}

.feature_broker .international-item:last-child {
  margin-bottom: 40px;
}
</style>