import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeTwo from '../views/Index'
// import Home from '../views/Home'
// import Dark from '../views/Dark'
// import HomeThree from '../views/HomeThree'
import Contact from '../views/Contact.vue'
import About from '../views/About.vue'
import Search from '../views/Search.vue'
import PerfectMoneyBrokers from '../views/PerfectMoneyBrokers.vue'
import BrokerReview from '../views/Brokers/review.vue'
import CryptoBroker from '../views/Brokers/crypto.vue'
import NetellerBroker from '../views/Brokers/neteller.vue'
import BrokerRegulators from '../views/Brokers/regulators.vue'
import BrokerByCountry from '../views/Brokers/byCountry.vue'
import BrokerProfile from '../views/Brokers/profile.vue'
import News from '../views/News/index.vue'
import NewsCalender from '../views/News/Calender.vue'
import NewsDetails from '../views/News/details.vue'
import ForexNoDepositBonus from '../views/ForexNoDepositBonus/index.vue'
import ForexDepositBonus from '../views/ForexDepositBonus/index.vue'
import CryptoNews from '../views/Crypto/news.vue'
import popularCoins from '../views/Crypto/Coins.vue'
import popularPaymentMethods from '../views/Crypto/popular-payment-methods.vue'
import Category from '../views/Category/index.vue'
import CategoryDetails from '../views/Category/details.vue'
import Privacy from '../views/Privacy.vue'
import usageConditions from '../views/usageConditions.vue'
import Regulators from '../views/Regulators.vue'
import addPromotion from '../views/addPromotion.vue'
import Archive from '../views/Archive.vue'
import fourOfour from '../components/404.vue'
import PostOne from '../components/Posts/PostOne/index.vue'
import PostTwo from '../components/Posts/PostTwo/index.vue'
import PostThree from '../components/Posts/PostThree/index'
import Business from '../components/category/Buisness.vue'
import Entertainment from '../components/category/Entertainment.vue'
import Features from '../components/category/Feature.vue'
import Sports from '../components/category/Sports.vue'
import Trending from '../components/category/Trending.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LayoutMain',
    component: HomeTwo,
  }, 
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  {
    path: '/perfect-money-brokers',
    name: 'PerfectMoneyBrokers',
    component: PerfectMoneyBrokers
  },
  {
    path: '/regulators',
    name: 'Regulators',
    component: Regulators
  },
  {
    path: '/brokers/review',
    name: 'BrokerReview',
    component: BrokerReview
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/brokers/crypto',
    name: 'CryptoBroker',
    component: CryptoBroker
  },
  {
    path: '/brokers/neteller',
    name: 'NetellerBroker',
    component: NetellerBroker
  },
  {
    path: '/regulators/brokers',
    name: 'BrokerRegulators',
    component: BrokerRegulators
  },
  {
    path: '/brokers/:country',
    name: 'BrokerByCountry',
    component: BrokerByCountry
  },
  {
    path: '/others/:category',
    name: 'Others',
    component: Category
  },
  {
    path: '/others/:category/:id',
    name: 'OthersDetails',
    component: CategoryDetails
  },
  {
    path: '/brokers/profile/:slug',
    name: 'BrokerProfile',
    component: BrokerProfile
  },
  {
    path: '/crypto-news/:slug',
    name: 'CryptoNews',
    component: CryptoNews
  },
  {
    path: '/news/:slug',
    name: 'News',
    component: News
  },
  {
    path: '/popular-coins',
    name: 'popularCoins',
    component: popularCoins
  },
  {
    path: '/popular-payment-methods',
    name: 'popularPaymentMethods',
    component: popularPaymentMethods
  },
  {
    path: '/economic-calendar',
    name: 'NewsCalender',
    component: NewsCalender
  },
  {
    path: '/news/:slug/:id',
    name: 'NewsDetails',
    component: NewsDetails
  },
  {
    path: '/forex-deposit-bonus',
    name: 'forexDepositBonus',
    component: ForexDepositBonus
  },
  {
    path: '/forex-no-deposit-bonus',
    name: 'forexNoDepositBonus',
    component: ForexNoDepositBonus
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: Privacy
  },
  {
    path: '/usage-terms-conditions',
    name: 'usageConditions',
    component: usageConditions
  },
  {
    path: '/add-promotion',
    name: 'addPromotion',
    component: addPromotion
  },
  {
    path: '/archive',
    name: 'Archive',
    component: Archive
  },
  {
    path: '/404',
    name: '404',
    component: fourOfour
  },
  {
    path: '/posts/postOne',
    name: 'PostOne',
    component: PostOne
  },
  {
    path: '/posts/posttwo',
    name: 'PostTwo',
    component: PostTwo
  },
  {
    path: '/posts/postthree',
    name: 'PostThree',
    component: PostThree
  },
  {
    path: '/business',
    name: 'Business',
    component: Business
  },
  {
    path: '/entertainment',
    name: 'Entertainment',
    component: Entertainment
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
  {
    path: '/sports',
    name: 'Sports',
    component: Sports
  },
  {
    path: '/trending',
    name: 'Trending',
    component: Trending
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

})
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router
