<template>
    <div> 
        <div class="container-fluid"> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="card mt-4">
                                <div class="card-header">
                                    <h4 class="card-title">Forex No Deposit Bonus</h4>
                                </div>
                                <div class="card-body about-content">
                                    Dive into the world of Forex trading with our exclusive No Deposit Bonus! Experience the thrill of trading without risking your own capital. Perfect for both beginners and seasoned traders, this offer lets you test strategies, explore new markets, and boost your skills—all while enjoying risk-free opportunities. Don’t miss out on your chance to maximize profits and elevate your trading game today! Start your journey with a bonus that gives you the freedom to trade smart.
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <NewsTabs />
                        </div>
                        <div class="col-lg-5 col-md-6 col-12 mt-5">
                            <RightBar :datas="Posts" :tabs="sideBarTabs" />
                        </div>
                    </div>
        </div>
 
    </div>
</template>

<script>
    import trendingPost from "@/components/Data/HomeTwoPost";
    import RightBar from "@/components/Partials/RightBar.vue";
    import Posts from "@/components/Data/NewsRowCard";
import NewsTabs from "@/components/HomeTwo/NewsTabs.vue";

    export default {
        metaInfo () {
    return {
      title: "Forex No Deposit Bonus - FXNewsNow",
      meta: [
      { charset: 'utf-8' },
      { name: 'description', content: "Discover the best forex no deposit bonuses with FXNewsNow and start trading with free capital." },
      { name: 'keyword', content: "forex no deposit bonus, free forex trading, forex promotions"},
    ],
    }
  }, 
        components: {
            RightBar,
            NewsTabs,
        },
        data: () => ({
            sideBarTabs: [    {
        title: "Weekly Special",
        value: 1,
      },
      {
        title: "Sessional Bonus",
        value: 2,
      },
      {
        title: "Latest",
        value: 3,
      },
            ],
            Posts: Posts.data,
            selected: "latest",
            scrollTop: 0,
            sidebar: false,
            trendingPost: trendingPost.trending,
            featurePost: trendingPost.features,
            buisnessPost: trendingPost.buisness,
            mostViews: trendingPost.trending,
            finance: trendingPost.finance,
            videoNews: trendingPost.videoNews,
            morePost: trendingPost.morePost,
            sports: trendingPost.sports,
            otherfinance: trendingPost.otherfinance,
        }),
        mounted() {
            document.body.classList.add("home-3-bg");
            window.addEventListener('scroll', this.handleScroll);

        },
        beforeDestroy() {
            document.body.classList.remove("home-3-bg");
            window.removeEventListener('scroll', this.handleScroll);

        },
        created() {
            document.addEventListener("scroll", this.topToBottom);
        },
        watch: {
            // Watch the scrollTop property for changes
            scrollTop(newValue, oldValue) {
                // Do something with the new value or perform additional actions
                console.log('scrollTop changed:', newValue);
                console.log('scrollTop oldValue:', oldValue);

                this.handleScroll();
            },
        },
        methods: {
            handleScroll() {
                // Update the scrollTop data property with the current scroll position
                // this.scrollTop = document.documentElement.scrollTop;
                // let currentTop = 206-this.scrollTop;
                // const ads= document.querySelector(".leftAds");
                // const adsRight= document.querySelector(".rightAds");
                // if(this.scrollTop<=206){
                //   ads.style.top=`${currentTop}px`
                //   adsRight.style.top=`${currentTop}px`
                // }
            },
            toggleSidebar() {
                this.sidebar = !this.sidebar;
            },
            topToBottom() {
                const result = document.querySelector(".go-top");
                if (
                    document.body.scrollTop > window.innerHeight ||
                    document.documentElement.scrollTop > window.innerHeight
                ) {
                    console.log(document.documentElement.scrollTop, 'documentElement.scrollTop')
                    console.log(document.body.scrollTop, 'scrollTop')
                    console.log(window.innerHeight, 'innerHeight')
                    result.classList.add("active");
                } else {
                    result.classList.remove("active");
                }
            },
        },
    };
</script>

<style>
    .home_ad .international-post-items {
        height: auto !important;
    }
</style>