<template>
    <div> 
        <div class="container-fluid"> 
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="about-tab-btn mt-40">
                              <div> 
                              </div>
                              <div class="card mt-4">
                                <div class="card-header">
                                    <h4 class="card-title
                  ">{{page.name}}</h4>
                                </div>
                                <div class="card-body about-content">
                                    <div v-html="page.content"></div>
                  
                                </div>
                            </div>
                              </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-12 mt-5">
                            <RightBar :datas="Posts" :tabs="sideBarTabs" />
                        </div>
                    </div>
               
        </div>
 
    </div>
</template>

<script>
    import trendingPost from "@/components/Data/HomeTwoPost";
    import RightBar from "@/components/Partials/RightBar.vue";
    import Posts from "@/components/Data/NewsRowCard";

    export default {
        components: {
            RightBar
        },
        
        data() {
            return {
              pagination: {
                grand_total: 0,
        current: 1,
        total: 1,
        perPage: 8,
      },
      items: [5, 10, 15, 20, 40, 50, 100],
                newses: [],
                coins: [],
                slug:this.$route.params.slug,
                sideBarTabs: [    {
        title: "Weekly Special",
        value: 1,
      },
      {
        title: "Sessional Bonus",
        value: 2,
      },
      {
        title: "Latest",
        value: 3,
      },
            ],
            Posts: Posts.data,
            selected: "latest",
            scrollTop: 0,
            sidebar: false,
            page: false,
            trendingPost: trendingPost.trending,
            featurePost: trendingPost.features,
            buisnessPost: trendingPost.buisness,
            mostViews: trendingPost.trending,
            finance: trendingPost.finance,
            videoNews: trendingPost.videoNews,
            morePost: trendingPost.morePost,
            sports: trendingPost.sports,
            otherfinance: trendingPost.otherfinance,
            loading:false
            };
        }, 
        mounted() {
            document.body.classList.add("home-3-bg");
            window.addEventListener('scroll', this.handleScroll);
          this.getNews(this.slug)
        },
        beforeDestroy() {
            document.body.classList.remove("home-3-bg");
            window.removeEventListener('scroll', this.handleScroll);

        },
        created() {
            this.getPage()
            document.addEventListener("scroll", this.topToBottom);
        },
        watch: {
            $route(to) {
                this.slug = to.params.slug;
                this.getNews(this.slug)
            },
            scrollTop(newValue, oldValue) {
                // Do something with the new value or perform additional actions
                console.log('scrollTop changed:', newValue);
                console.log('scrollTop oldValue:', oldValue);

                this.handleScroll();
            },
        },
        methods: {
            getPage(){
                this.$axios
                .get("/global/website/page/popular-payment-methods", {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.page=result.data.data;
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                     console.log(err)
                    }

                });
            },
          getNews(slug){
            this.loading=true
            var data = {
        perPage: this.pagination.perPage,
        page: this.pagination.current,
        searchText: this.search,
      };
            this.$axios
                .get("/global/website/news/"+slug, {
                    params: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                  this.newses = result.data.data;
          this.pagination.current = result.data.meta.current_page;
          this.pagination.total = result.data.meta.last_page;
          this.pagination.grand_total = result.data.meta.total;
          this.loading=false

                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                     console.log(err)
                    }

                });
          },
            toggleSidebar() {
                this.sidebar = !this.sidebar;
            },
            topToBottom() {
                const result = document.querySelector(".go-top");
                if (
                    document.body.scrollTop > window.innerHeight ||
                    document.documentElement.scrollTop > window.innerHeight
                ) {
                    result.classList.add("active");
                } else {
                    result.classList.remove("active");
                }
            },
        },
    };
</script>

<style>
    .home_ad .international-post-items {
        height: auto !important;
    }
</style>