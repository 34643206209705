<template>
    <div>
        <div class="aboutWrapper">
            <div class="container">
                <header class="header">
                    <h1 id="title" class="text-center">Add New Promotion</h1>
                </header>
                <div class="form-wrap">	
                    <form @submit.prevent="submit()">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label id="name-label" for="name">Bonus Title</label>
                                    <input type="text" v-model="data.title" placeholder="Enter Bonus Title" class="form-control" required>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label id="email-label" for="email">Bonus URL</label>
                                    <input type="text" name="email" id="email" v-model="data.bonus_url" placeholder="Enter Bonus URL " class="form-control" required>
                                </div>
                            </div>
                        </div>
                        
                  

                        <div class="row">
                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label id="name-label" for="name">Bonus For</label>
                                    <input type="text" v-model="data.bonus_for" placeholder="Enter URL" class="form-control" required>
                                </div>
                            </div> -->
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label id="email-label" for="email">Type</label>
                                 <select  @change="selectType($event)" class="form-control" id="">
                                    <option value="">Select Type</option>
                                    <option v-for="(type,index) in promotionTypes" :key='index' :value="type.id">{{type.name}}</option> 
                                 </select>
                                </div>
                            </div>
                        </div>
                 
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Bonus Details</label>
                                    <textarea  v-model="data.description" id="comments" class="form-control" name="comment" placeholder="Enter your comment here..."></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                              <div class="form-group">
                                  <label id="number-label" for="number">Bonus Image (JPG/PNG: 1200x600)  <small>*</small></label>
                                  <input type="file" @change="selectFile($event)" class="form-control" >
                              </div>
                          </div>
                      </div>
                        <div class="row">
                            <div class="col-md-4">
                                <button type="submit" id="submit" class="btn btn-primary btn-block">Submit</button>
                            </div>
                        </div>
            
                    </form>
                </div>	
            </div>

        </div>

        <div class="add-area text-center my-5 pb-5">
            <a href="#" class="mb-5">
                <img src="@/assets/images/ads/one_ad.png" alt="title" />
            </a>
        </div>
     
    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
                promotionTypes:[
      {
        id:1,name:'Header Advertisement',
      },
      {
        id:2,name:'Left Side Advertisement One',
      },
      {
        id:3,name:'Left Side Advertisement Two',
      },
      {
        id:4,name:'Right Side Advertisement One',
      },
      {
        id:5,name:'Right Side Advertisement Two',
      },
      {
        id:6,name:'Middle Advertisement',
      },
      {
        id:7,name:'Box Advertisement One',
      },
      {
        id:8,name:'Box Advertisement Two',
      },
      {
        id:9,name:'Box Advertisement Three',
      },
      {
        id:10,name:'Bottom Advertisement Large',
      },
      {
        id:11,name:'Bottom Advertisement Small',
      },
      {
        id:12,name:'Review Page Box Advertisement One',
      },
      {
        id:13,name:'Review Page Box Advertisement Two',
      },
      {
        id:14,name:'Review Page Bottom Advertisement',
      },
    ],
                data: {
          title: null,
          bonus_url: null,
          bonus_for: "",
          cover: null,
          type: [],
          description: null,
        },
            }
        },
        created() {
            document.addEventListener("scroll", this.topToBottom);
        },
        methods: {
            submit(){
    
                let fd = new FormData();
        for (const [key, value] of Object.entries(this.data)) {
          if (value !== null) {
            if (key != 'type' && key != 'cover') {
              fd.append(key, value);
            }
          }
        }
        fd.append("banner", this.data.cover);
        for (var i = 0; i < this.data.type.length; i++) {
          fd.append("type[]", this.data.type[i]);
        }

        this.$axios
                .post("/global/website/promotion", fd, {
            headers: { 
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log(res)
            this.$toast.open({
    message: "Promotion Request Send Successfully",
    type: 'success',
}); 
// push to the home page
this.$router.push('/');
          })
          .catch((error) => {
            if (error.response) {
                console.log(error.response.data.errors)
                // object of errors
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    key;
                    this.$toast.open({
                        message: value[0],
                        type: 'error',
                    });
                }
            }
          });
            },
            selectFile(file){
                this.data.cover=file.target.files[0]  
            },
            selectType(ev){
                // select tag value is in ev.target.value
                this.data.type=[]
                this.data.type.push(ev.target.value)
            },
            toggleSidebar() {
                this.sidebar = !this.sidebar;
            },
            topToBottom() {
                const result = document.querySelector(".go-top");
                if (
                    document.body.scrollTop > window.innerHeight ||
                    document.documentElement.scrollTop > window.innerHeight
                ) {
                    result.classList.add("active");
                } else {
                    result.classList.remove("active");
                }
            },
        },
    };
</script>

<style scoped >
.aboutWrapper {
    padding: 100px 0px;
}
.header{
	margin-bottom: 80px;
}
#description{
	font-size: 24px;
}

.form-wrap{
	background: rgba(255,255,255,1);
	width: 100%;
	max-width: 850px;
	padding: 50px;
	margin: 0 auto;
	position: relative;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
}
.form-wrap:before{
	content: "";
	width: 90%;
	height: calc(100% + 60px);
	left: 0;
	right: 0;
	margin: 0 auto;
	position: absolute;
	top: -30px;
	background: #17222b;
	z-index: -1;
	opacity: 0.8;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
}
.form-group{
	margin-bottom: 25px;
}
.form-group > label{
	display: block;
	font-size: 18px;	
	color: #000;
}
.custom-control-label{
	color: #000;
	font-size: 16px;
}
.form-control{
	height: 50px;
	background: #ecf0f4;
	border-color: transparent;
	padding: 0 15px;
	font-size: 16px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.form-control:focus{
	border-color: #17222b;
	-webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
	-moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}
textarea.form-control{
	height: 160px;
	padding-top: 15px;
	resize: none;
}

.btn{
	padding: .657rem .75rem;
	font-size: 18px;
	letter-spacing: 0.050em;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.btn-primary {
  color: #fff;
  background-color: #17222b;
  border-color: #17222b;
}

.btn-primary:hover {
  color: #17222b;
  background-color: #ffffff;
  border-color: #17222b;
	-webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
	-moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

.btn-primary:focus, .btn-primary.focus {
  color: #17222b;
  background-color: #ffffff;
  border-color: #17222b;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
	-moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #17222b;
  background-color: #ffffff;
  border-color: #17222b;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
	-moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}
</style>
