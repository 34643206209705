import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex);
export const store = new Vuex.Store({
    state: {
        videoPopup:false,
        promotions:[],
        settings:null
    },
    getters: {
        getVideoPopup: state => {
            return state.videoPopup
        },
        getPromotions: state => {
            return state.promotions
        }
    },
    mutations: {
        SET_VALUE_VIDEO:(state, payload)=> {
            console.log(payload)
            state.videoPopup = payload
        },
        SET_PROMOTIONS:(state, payload) => {
            state.promotions = payload
        },
        SET_SETTINGS:(state, payload) => {
            state.settings = payload
        }

    },
    actions: {
        toggleVideo({state,commit }) {
            commit('SET_VALUE_VIDEO',!state.videoPopup)
        },
        getPromotions({commit}) {
            axios.get("/global/website/promotions", {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(response => {
                commit('SET_PROMOTIONS',response.data)
            })
            .catch(error => {
                console.log(error)
            })

        },
        getSettings({commit}) {
            axios.get("/global/website/settings", {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(response => {
                commit('SET_SETTINGS',response.data.data)
            })
            .catch(error => {
                console.log(error)
            })

        }
    }

})